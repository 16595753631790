import { useLocale, useTranslations } from "next-intl";
import { useState, useTransition, useCallback } from "react";
import { toast } from "react-toastify";

import type { LanguageCode } from "@/components/hooks/useLanguage";

import { setCookie } from "@/app/_actions/setCookie";
import { setRequestLocale } from "@/app/_actions/setRequestLocale";
import { ToastIcon } from "@/components/ToastIcon";
import { ToastMessage } from "@/components/ToastMessage";
import { useRouter, usePathname } from "@/i18n";
import { useGTMContext } from "@/providers";
import { generateUUID } from "@/utils/crypto";
import { COOKIES } from "~/.constants/cookies";
import { GE } from "~/.constants/gtmEvents";

import { COOKIE_OPTIONS } from "./vars";

export const useChangeLanguage = () => {
  const tToast = useTranslations("HomePage.toast");

  const { sendGTM } = useGTMContext();

  const locale = useLocale();
  const router = useRouter();
  const pathname = usePathname();

  const [isPending, startTransition] = useTransition();

  const [selectedLanguage, setSelectedLanguage] = useState<LanguageCode>(
    locale as LanguageCode,
  );

  const handleError = useCallback(
    (error: Error) => {
      toast.error(
        <ToastMessage title={tToast("error")} text={error.message} />,
        {
          icon: ({ type }) => ToastIcon(type),
          toastId: generateUUID(),
        },
      );
    },
    [tToast],
  );

  const handleLanguageChange = useCallback(
    async (newLanguage: LanguageCode) => {
      if (newLanguage === selectedLanguage || isPending) {
        return;
      }

      try {
        sendGTM({
          event: GE.CHANGE_LANGUAGE,
          ecommerce: {
            change_language_from: locale,
            change_language_to: newLanguage,
          },
        });

        setSelectedLanguage(newLanguage);

        startTransition(async () => {
          await Promise.all([
            setCookie(COOKIES.NEXT_LOCALE, newLanguage, COOKIE_OPTIONS),
            setRequestLocale(newLanguage),
          ]);

          router.replace(pathname, { locale: newLanguage });
        });
      } catch (err) {
        handleError(err as Error);
      }
    },
    [
      selectedLanguage,
      isPending,
      sendGTM,
      locale,
      router,
      pathname,
      handleError,
    ],
  );

  return {
    selectedLanguage,
    isPending,
    handleLanguageChange,
  };
};
