"use client";

import { GE } from ".constants/gtmEvents";
import { usePortalContext } from "@cloai/uikit";
import { SyntheticEvent, useEffect, useState } from "react";

import { useClickUndress } from "@/components/user-flow/controls/useClickUndress";
import { useGTMContext, useUndressContext } from "@/providers";
import { generateUUID } from "@/utils/crypto";

export const useAnimate = (token: string | null) => {
  const { closePortal } = usePortalContext();
  const {
    initialSettings,
    clickUndressingFile,
    undressingsFiles,
    onReset,
    setLoading,
    setSettings,
    setClickFileId,
    setUndressingsFiles,
  } = useUndressContext();
  const { sendGTM } = useGTMContext();

  const { onSignUndressAnimate } = useClickUndress(token);

  const [isReadyToAnimate, setIsReadyToAnimate] = useState(false);

  const handleAnimate = async (event: SyntheticEvent) => {
    try {
      const url = clickUndressingFile?.fileUrl;

      if (!url) {
        throw new Error("No file URL");
      }

      await onReset(event, true);

      const newFile = { id: generateUUID(), fileUrl: url };

      // Обновляем состояние с массивом изображений
      setUndressingsFiles([newFile]);

      // Обновляем настройки для нового изображения
      setSettings([
        {
          ...initialSettings,
          id: newFile.id,
        },
      ]);

      // Выбираем добавленное изображение в качестве активного
      setClickFileId(newFile.id);

      sendGTM({ event: GE.ANIMATE_POPUP__CLICK_ANIMATE });

      // Делаем паузу, чтобы React успел обновить состояние

      // TODO: делаем запрос на генку анимации
      // TODO: ПОЧИНИТЬ
      // await onSignUndressAnimate();

      // Устанавливаем флаг готовности к анимации
      setIsReadyToAnimate(true);

      closePortal();
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  // Отслеживаем изменение состояния и наличие файлов
  useEffect(() => {
    if (isReadyToAnimate && undressingsFiles.length > 0) {
      onSignUndressAnimate();
      setIsReadyToAnimate(false); // Сбрасываем флаг
    }
  }, [isReadyToAnimate, undressingsFiles, onSignUndressAnimate]);

  return handleAnimate;
};
