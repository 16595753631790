export const getLocalePrice = (currency: string, price: number) => {
  switch (currency) {
    case "RUB":
      return `${price} ₽`;
    case "IDR":
      return `${price} Rp`;
    case "EUR":
      return `${price} €`;
    case "GBP":
      return `${price} £`;
    case "CNY":
      return `${price} ¥`;
    case "BRL":
      return `${price} BR`;
    default:
      return `$${price} US`;
  }
};

export const getFormatedPrice = (currency: string, price: number) =>
  new Intl.NumberFormat("en", {
    style: "currency",
    currency: currency,
    // maximumSignificantDigits: 3,
  })
    .format(price)
    .toString();
