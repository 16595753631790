"use client";

import { PORTALS } from ".constants/portals";
import {
  Button,
  ModalContent,
  ModalFooter,
  Box,
  Text,
  PortalModal,
  usePortalContext,
  Title,
} from "@cloai/uikit";
import { useTranslations } from "next-intl";
import { FC, SyntheticEvent } from "react";

import { useUndressContext } from "@/providers";

export const GenerationErrorPortal: FC = () => {
  const t = useTranslations("HomePage");
  const { closePortal } = usePortalContext();
  const { onReset } = useUndressContext();

  const handleReset = (event: SyntheticEvent) => {
    onReset(event);
    closePortal();
  };

  return (
    <PortalModal portalId={PORTALS.GenerationError} fixed>
      <ModalContent>
        <Text text="😟" fontSize={64} center />
        <Box gap={16}>
          <Title
            Component={"p"}
            color="orange"
            text={t("errorOccured")}
            center
          />
          <Text fontSize={18} text={t("moveOn")} center />
        </Box>
      </ModalContent>

      <ModalFooter>
        <Box gap={12} row>
          <Button
            iconLeft="autorenew"
            onClick={handleReset}
            text={t("New")}
            size="large"
            wide
          />
          {/* <DownloadImage /> */}
        </Box>
      </ModalFooter>
    </PortalModal>
  );
};
