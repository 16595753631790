"use client";

import { PORTALS } from ".constants/portals";
import {
  Button,
  ModalContent,
  ModalFooter,
  ModalHeader,
  PortalModal,
  Text,
  usePortalContext,
} from "@cloai/uikit";
import { useTranslations } from "next-intl";
import { FC, SyntheticEvent } from "react";

import { useUndressContext } from "@/providers";

export const ResetReUseFacePortal: FC = () => {
  const t = useTranslations("ResetReUseFaceModal");
  const { onReset, loading } = useUndressContext();
  const { closePortal } = usePortalContext();

  const handleReset = async (event: SyntheticEvent) => {
    await onReset(event);
    closePortal();
  };

  return (
    <PortalModal portalId={PORTALS.ResetReUseFace} variant="fixed">
      <ModalHeader>{t("title")}</ModalHeader>
      <ModalContent>
        <Text text={t("text")} />
      </ModalContent>

      <ModalFooter>
        <Button
          text={t("buttonText")}
          wide
          centerText
          onClick={handleReset}
          loading={loading}
        />
      </ModalFooter>
    </PortalModal>
  );
};
