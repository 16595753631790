"use client";

import { PORTALS } from ".constants/portals";
import {
  Box,
  Button,
  InfoBoxV3,
  ModalContent,
  ModalFooter,
  PortalModal,
  Subtitle,
  Text,
  usePortalContext,
} from "@cloai/uikit";
import { useTranslations } from "next-intl";
import { FC, useMemo } from "react";
import { useCookies } from "react-cookie";

export const NewBalancePortal: FC = () => {
  const t = useTranslations("NewBalanceModal");
  const { closePortal } = usePortalContext();
  const [cookies, , removeCookie] = useCookies(["newBalance"]);

  const newBalance = cookies?.newBalance as {
    coins: number;
    freeCoins: number;
    oldCoins: number;
    oldFreeCoins: number;
  };

  const { plusCoins, plusFreeCoins, noticeFree, noticeVip } = useMemo(() => {
    try {
      if (!newBalance) {
        return {
          plusCoins: 0,
          plusFreeCoins: 0,
          noticeFree: "0",
          noticeVip: "0",
        };
      }

      const plusCoins = newBalance?.coins - newBalance?.oldCoins;
      const plusFreeCoins = newBalance?.freeCoins - newBalance?.oldFreeCoins;

      const noticeVip =
        plusCoins < 1000 && plusCoins > 0 ? `x 800%` : `+ ${plusCoins}`;
      const noticeFree =
        plusFreeCoins < 1000 && plusFreeCoins > 0
          ? `x 800%`
          : `+ ${plusFreeCoins}`;

      return {
        plusCoins,
        plusFreeCoins,
        noticeFree,
        noticeVip,
      };
    } catch {
      return {
        plusCoins: 0,
        plusFreeCoins: 0,
        noticeFree: "0",
        noticeVip: "0",
      };
    }
  }, [newBalance]);

  const handleClose = () => {
    closePortal();
    removeCookie("newBalance");
  };

  return (
    <PortalModal portalId={PORTALS.NewBalance} fixed onClose={handleClose}>
      <ModalContent>
        <Subtitle
          text=""
          center
          style={{ fontSize: "64px", padding: "10px" }}
        />

        <Subtitle text={t("title")} color="orange" center />

        <Box gap={8} center>
          <Text text={t("text1")} center />
        </Box>

        <Box row center>
          {plusFreeCoins > 0 && (
            <InfoBoxV3
              text={noticeFree}
              iconRight="star"
              size="large"
              color="violet"
              title={`${t.rich("noticeFree", { coins: noticeFree })}`}
            />
          )}

          {plusCoins > 0 && (
            <InfoBoxV3
              text={noticeVip}
              iconRight="diamond"
              size="large"
              title={`${t.rich("noticeVip", { coins: noticeVip })}`}
            />
          )}
        </Box>
        <Text text={t("text2")} center />
      </ModalContent>

      <ModalFooter>
        <Button
          text={t("buttonText")}
          size="large"
          wide
          centerText
          onClick={handleClose}
        />
      </ModalFooter>
    </PortalModal>
  );
};
