"use client";

import { PORTALS } from ".constants/portals";
import { ModalContent, ModalHeader, Text, PortalModal } from "@cloai/uikit";
import { useTranslations } from "next-intl";
import { FC } from "react";

export const CaptchaPortal: FC = () => {
  const t = useTranslations("CaptchaModal");

  return (
    <PortalModal portalId={PORTALS.Captcha}>
      <ModalHeader>{t("title")}</ModalHeader>

      <ModalContent>
        <Text text={t("text")} center />

        {/* TODO: сюда надо вставить капчу */}
      </ModalContent>
    </PortalModal>
  );
};
