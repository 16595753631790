"use client";

import { GE } from ".constants/gtmEvents";
import { PATH_AUTH, PATH_NOT_ENOUGH_COINS } from ".constants/links";
import { PORTALS } from ".constants/portals";
import { ApolloError, useApolloClient, useMutation } from "@apollo/client";
import { usePortalContext } from "@cloai/uikit";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { findIndex, isEmpty, some } from "lodash";
import { useTranslations } from "next-intl";
import { useCallback } from "react";
import { toast } from "react-toastify";

import { InputUndress, InputUndressType } from "@/.gql/graphql";
import { GET_UNDRESSINGS, SIGN_UNDRESS } from "@/.graphql";
import { verifyCaptchaAction } from "@/app/_actions/captcha";
import {
  checkCoins,
  cutVideo,
  prepareInputs,
  updateUndressingsFiles,
} from "@/components/user-flow/utils/undressHelpers";
import { useRouter } from "@/i18n";
import {
  useAiRequestsContext,
  useUndressContext,
  useGTMContext,
  useProfileContext,
  Profile,
} from "@/providers";

dayjs.extend(utc);

interface UndressHandlers {
  onSignUndressImage: () => Promise<void>;
  onSignUndressRemix: () => Promise<void>;
  onSignUndressAnimate: () => Promise<void>;
  onSignUndressVideo: () => Promise<void>;
}

export const useClickUndress = (token: string | null): UndressHandlers => {
  const router = useRouter();
  const tSystemesNotify = useTranslations("SystemesNotify");
  const t = useTranslations("HomePage");
  const profile = useProfileContext();
  const { sendGTM } = useGTMContext();
  const { cache } = useApolloClient();

  const [signUndress] = useMutation(SIGN_UNDRESS, {
    context: {
      headers: {
        "apollo-require-preflight": true,
      },
    },
  });
  const { openPortal } = usePortalContext();
  const {
    clickFileId,
    clickSettings,
    closeCustomizeSettings,
    selectedVipSettings,
    setClickFileId,
    setLoading,
    setStartGenTime,
    undressingCost,
    undressingsFiles,
    videoSettings,
    reUseFaceObj,
  } = useUndressContext();

  const { sets } = useAiRequestsContext();
  const { gender, prompt } = clickSettings;

  /**
   * Проверка монет для VIP или FREE режима:
   * - если выбраны VIP-настройки - хватает ли VIP-коинов
   * - если НЕ выбраны VIP-настройки - хватает ли FREE-коинов
   * - если пользователь гость — проверяем капчу
   * */
  const checkUserStatus = useCallback(
    async (
      profile: Profile | null,
      token: string | null,
      selectedVipSettings: boolean,
      undressingCost: number,
    ) => {
      if (profile) {
        checkCoins(profile, selectedVipSettings, undressingCost);
        if (profile.isBlockedUndress) {
          throw new Error("USER_IS_BLOCKED");
        }
      } else if (!token || !(await verifyCaptchaAction(token))) {
        throw new Error("AUTH");
      }
    },
    [],
  );

  /**
   * Основная функция запуска процесса раздевания (`onSignUndress`).
   * Выполняет следующие шаги:
   * - Проверка монет (если `profile` существует).
   * - Если гость, проверка капчи.
   * - Подготавливает массив изображений для запроса.
   * - Вызывает мутацию `signUndress` с нужными параметрами.
   * - Обновляет кэш undressings.
   * - Запускает GTM-событие.
   * - Обновляет время старта генерирования (`setStartGenTime`).
   */
  const onSignUndress = useCallback(
    async (undressType: InputUndressType) => {
      try {
        closeCustomizeSettings();
        setLoading(true);

        await checkUserStatus(
          profile,
          token,
          selectedVipSettings,
          undressingCost,
        );

        const aiRequestsIds = [
          clickSettings.bodyType,
          clickSettings.breastSize,
          clickSettings.age,
          clickSettings.buttSize,
          clickSettings.pose,
          clickSettings.costume,
          clickSettings.quality,
          clickSettings.environment,
          clickSettings.skinColor,
          clickSettings.hairColor,
        ];

        const clickIndex = findIndex(undressingsFiles, { id: clickFileId });
        let inputs: InputUndress[] = [];

        if (videoSettings.videoDuration > 0) {
          const file = await cutVideo(
            videoSettings.video,
            videoSettings.videoCutPoints[0],
            videoSettings.videoCutPoints[1],
          );

          inputs = [
            {
              file,
              aiRequestsIds,
              gender,
              prompt,
              undressType,
            } as InputUndress,
          ];
        } else {
          // Если выбран сет (коллекция), обрабатываем логические изменения массива `undressingsFiles`
          const _undressingsFiles = updateUndressingsFiles(
            sets,
            clickSettings.set,
            "set",
            undressingsFiles,
          );

          // Подготовка массива изображений для запроса `signUndress`
          inputs = await prepareInputs(
            _undressingsFiles,
            clickSettings,
            aiRequestsIds,
            undressType,
          );
        }

        if (isEmpty(inputs)) {
          throw new Error("Inputs is empty");
        }

        const { data } = await signUndress({
          variables: {
            inputs,
            lastUndressingIds: reUseFaceObj?.lastUndressIds!,
          },
        });

        if (!data?.signUndress) {
          throw new Error("No Data in `signUndress`");
        }

        const hasPaid = some(data.signUndress, "isPaid");

        if (hasPaid) {
          sendGTM({ event: GE.UNDRESS_VIP_COIN });
        } else if (profile) {
          sendGTM({ event: GE.UNDRESS });
        } else {
          sendGTM({ event: GE.UNDRESS_GUEST });
        }

        const newUndressingsFiles = data.signUndress;

        // Обновляем кэш
        cache.writeQuery({
          query: GET_UNDRESSINGS,
          data: { getUndressings: newUndressingsFiles },
        });

        setClickFileId(newUndressingsFiles[clickIndex]?.id);
        setStartGenTime(dayjs());
      } catch (err) {
        console.log(err);
        const error = err as Error;

        if (error?.message === "PATH_NOT_ENOUGH_COINS") {
          sendGTM({ event: GE.FLOW_NOT_ENOUGH_COINS__REDIRECT_TO_BUY });
          router.push(PATH_NOT_ENOUGH_COINS, { scroll: false });
          return; // Завершаем обработку
        }

        if (error?.message === "AUTH") {
          sendGTM({ event: GE.FLOW_ERROR_UNDRESS__REDIRECT_TO_AUTH });
          router.push(PATH_AUTH);
          return; // Завершаем обработку
        }

        if (error?.message === "USER_IS_BLOCKED") {
          sendGTM({ event: GE.FLOW_ERROR_USER_IS_BLOCKED });
          openPortal(PORTALS.BlockUser);
          return; // Завершаем обработку
        }

        // Обработка ошибок Apollo
        if (err instanceof ApolloError) {
          const code = err?.graphQLErrors[0]?.extensions?.code;
          if (code === "AUTH") {
            sendGTM({ event: GE.FLOW_ERROR_UNDRESS__REDIRECT_TO_AUTH });
            router.push(PATH_AUTH);
            return;
          } else if (code === "writeSupport") {
            sendGTM({
              event: GE.FLOW__GENERATE_ERROR,
              ecommerce: { error_message: error.message },
            });
            console.log("UNDRESS ERROR: ", error.message);
            toast.error(tSystemesNotify(code));
            return;
          }
        }

        sendGTM({
          event: GE.FLOW__GENERATE_ERROR,
          ecommerce: { error_message: error.message },
        });
        console.log("UNDRESS ERROR: ", error.message);
        toast.error(t("toast.tryLater"));
        throw error;
      } finally {
        setLoading(false);
      }
    },
    [
      cache,
      checkUserStatus,
      clickFileId,
      clickSettings,
      closeCustomizeSettings,
      gender,
      openPortal,
      profile,
      prompt,
      reUseFaceObj?.lastUndressIds,
      router,
      selectedVipSettings,
      sendGTM,
      setClickFileId,
      setLoading,
      setStartGenTime,
      sets,
      signUndress,
      t,
      tSystemesNotify,
      token,
      undressingCost,
      undressingsFiles,
      videoSettings.video,
      videoSettings.videoCutPoints,
      videoSettings.videoDuration,
    ],
  );

  const undressHandlers = {
    onSignUndressImage: useCallback(
      () => onSignUndress(InputUndressType.Image),
      [onSignUndress],
    ),

    onSignUndressRemix: useCallback(
      () => onSignUndress(InputUndressType.RemixFace),
      [onSignUndress],
    ),

    onSignUndressAnimate: () => onSignUndress(InputUndressType.Animation),

    onSignUndressVideo: useCallback(
      () => onSignUndress(InputUndressType.Video),
      [onSignUndress],
    ),
  };

  return undressHandlers;
};
