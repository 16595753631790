"use client";

import { PORTALS } from ".constants/portals";
import {
  ModalContent,
  Text,
  PortalModal,
  usePortalContext,
  Title,
} from "@cloai/uikit";
import { signOut } from "next-auth/react";
import { useTranslations } from "next-intl";
import { FC, useCallback } from "react";

import { setCookie } from "@/app/_actions/setCookie";

export const BlockUserPortal: FC = () => {
  const t = useTranslations("BlockUserModal");
  const { closePortal } = usePortalContext();

  const onLogout = useCallback(() => {
    signOut();
  }, []);

  const handleClose = async () => {
    await setCookie("app", "0");
    await setCookie("rulesAccepted", "0");
    onLogout();
    closePortal();
  };

  return (
    <PortalModal portalId={PORTALS.BlockUser} fixed onClose={handleClose}>
      <ModalContent>
        <Text text="🚫" fontSize={64} center />

        <Title
          Component="span"
          text={t.rich("title", {
            orange: (chunk) => <span data-color="orange">{chunk}</span>,
            red: (chunk) => <span data-color="red">{chunk}</span>,
          })}
          center
        />
      </ModalContent>

      {/* <ModalFooter>
        <Button
          text={t("buttonText")}
          color="red"
          centerText
          wide
          onClick={handleClose}
        />
      </ModalFooter> */}
    </PortalModal>
  );
};
