/**
 * Преобразует Base64-строку в объект File с учетом типа контента.
 * @param base64Data Строка в формате Base64 (например, "data:image/png;base64,...").
 * @param fileName Имя файла (по умолчанию "file").
 * @param isVideo Флаг, указывающий, является ли файл видео (по умолчанию false).
 * @returns File
 */
export function base64ToFile(
  base64Data: string,
  fileName = "file",
  isVideo = false,
): File {
  const base64String = base64Data.split(",")[1] || base64Data; // Учитываем случай, если префикс отсутствует
  const mimeType = isVideo ? "video/mp4" : "image/png"; // MIME-тип в зависимости от типа
  const defaultExtension = isVideo ? ".mp4" : ".png";
  const fullFileName = fileName.endsWith(defaultExtension)
    ? fileName
    : `${fileName}${defaultExtension}`;

  const binaryString = atob(base64String); // Декодируем Base64
  const byteArray = new Uint8Array(binaryString.length);
  for (let i = 0; i < binaryString.length; i++) {
    byteArray[i] = binaryString.charCodeAt(i);
  }

  return new File([byteArray], fullFileName, { type: mimeType });
}
