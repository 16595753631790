"use client";

import { PORTALS } from ".constants/portals";
import {
  ModalContent,
  PortalModal,
  ModalHeader,
  Text,
  Box,
} from "@cloai/uikit";
import { useTranslations } from "next-intl";
import { FC, ReactNode, useMemo } from "react";

import { useHeadersContext } from "@/providers";

export const PwaPortal: FC = () => {
  const t = useTranslations("PwaModal");

  const { ["user-agent"]: userAgent } = useHeadersContext();
  type TSystem = "MacOS" | "iOS" | "Windows" | "Android";
  const agent = useMemo(() => {
    switch (true) {
      case /iPhone|iPad|iPod/i.test(userAgent):
        return {
          system: "iOS",
          isntallIcon: "ios_share",
          addIcon: "add_box",
        };
      case /Macintosh/i.test(userAgent):
        return {
          system: "MacOS",
          isntallIcon: "ios_share",
          addIcon: "dock",
        };
      case /Windows/i.test(userAgent):
        return {
          system: "Windows",
          isntallIcon: "install_desktop",
          addIcon: "",
        };
      default:
        return {
          system: "Android",
          isntallIcon: "more_vert",
          addIcon: "add_to_home_screen",
        };
    }
  }, [userAgent]);

  return (
    <PortalModal portalId={PORTALS.PWA} variant="fixed">
      <ModalHeader>{t("title", { system: agent.system })}</ModalHeader>

      <ModalContent className="gap-1">
        <Text
          text={
            <Box gap={8} row align_center wrap>
              {t.rich(`${agent.system as TSystem}.text1`, {
                strong: (chunk: ReactNode) => (
                  <span data-color="white">
                    <strong>{chunk}</strong>
                  </span>
                ),
                icon: () => (
                  <span
                    className="material-icons"
                    data-icon={agent.isntallIcon}
                    data-color="white"
                  ></span>
                ),
              })}
            </Box>
          }
          color="gray"
        />
        <Text
          text={
            <Box gap={8} row align_center wrap>
              {t.rich(`${agent.system as TSystem}.text2`, {
                strong: (chunk: ReactNode) => (
                  <span data-color="white">
                    <strong>{chunk}</strong>
                  </span>
                ),
                icon: () => (
                  <span
                    className="material-icons"
                    data-icon={agent.addIcon}
                    data-color="white"
                  ></span>
                ),
              })}
            </Box>
          }
          color="gray"
        />
      </ModalContent>
    </PortalModal>
  );
};
