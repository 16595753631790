"use client";

import { GE } from ".constants/gtmEvents";
import { PORTALS } from ".constants/portals";
import {
  Button,
  Input,
  ModalContent,
  ModalFooter,
  PortalModal,
  Text,
  Title,
  TRANSITION_VARIANTS,
  usePortalContext,
} from "@cloai/uikit";
import { AnimatePresence, motion } from "framer-motion";
import { useTranslations } from "next-intl";
import Image from "next/image";
import { FC, useEffect } from "react";
import { useCookies } from "react-cookie";
import useMeasure from "react-use-measure";

import { useGTMContext } from "@/providers";
import Img from "@/styles/image/get-notify-popup-free.webp";

import { useEmail } from "./useEmail";

export const SubscribesPortalFREE: FC = () => {
  const t = useTranslations("SubscribesModal.free");
  const u = useTranslations("SubscribesModal.ui");
  const { currentPortal, closePortal } = usePortalContext();
  const { sendGTM } = useGTMContext();
  const [ref, bounds] = useMeasure();
  const [, setCookies] = useCookies(["subscribeEmailIsShowed"]);

  const {
    disable,
    email,
    error,
    handleChange,
    handleReset,
    isConfirm,
    loading,
    onClickConfirm,
    setIsConfirm,
  } = useEmail();

  useEffect(() => {
    if (currentPortal === PORTALS.SubscribesFREE) {
      setCookies("subscribeEmailIsShowed", 1, {
        maxAge: 60 * 60 * 24 * 3, // примерно 3 дня в секундах
      });

      sendGTM({ event: GE.SUBSCRIBE_FREE__SHOW });
    }
  }, [currentPortal, sendGTM, setCookies]);

  const handleClosePortal = () => {
    handleReset();
    closePortal();
    setIsConfirm(false);
  };

  return (
    <PortalModal portalId={PORTALS.SubscribesFREE}>
      <Image
        src={Img}
        alt="cover"
        className="pointer-events-none absolute inset-0 block h-auto w-full"
        priority
      />

      <ModalContent>
        <Title Component="p" text={t("title")} center fontSize={24} />

        {/** Обёртка с динамической высотой, зависящей от содержимого */}
        <motion.div
          animate={{ height: bounds.height }}
          transition={{ duration: 0.3, ease: "easeInOut" }}
        >
          <AnimatePresence mode="wait" initial={false}>
            <motion.div
              key={`get-notify-vip-${isConfirm}`}
              initial="exit"
              animate="enter"
              exit="exit"
              variants={TRANSITION_VARIANTS.modalInOut}
            >
              {/** Обёртка для измерения высоты контента */}
              <div ref={ref} className="flex flex-col gap-5">
                {!isConfirm && (
                  <>
                    <Text text={t("text1")} color="gray" />

                    <div className="flex flex-col gap-4">
                      <Text text={t("text2")} color="gray" />
                      <ul>
                        <Text
                          Component="li"
                          text={t("text2-clause1")}
                          color="gray"
                        />
                        <Text
                          Component="li"
                          text={t("text2-clause2")}
                          color="gray"
                        />
                      </ul>
                    </div>

                    <Text text={t("text3")} color="gray" />

                    <Input
                      name={u("inputLabel")}
                      placeholder="example@mail.net"
                      minLength={10}
                      icon="mail"
                      onChange={handleChange}
                      onReset={handleReset}
                      error={error}
                      loading={loading}
                      value={email}
                      autoComplete="email"
                    />
                  </>
                )}

                {isConfirm && (
                  <Text
                    text={u("checkEmail")}
                    center
                    color="orange"
                    className="m-auto"
                  />
                )}
              </div>
            </motion.div>
          </AnimatePresence>
        </motion.div>
      </ModalContent>

      <ModalFooter>
        {isConfirm ? (
          <Button
            text={u("closeButton")}
            wide
            centerText
            onClick={handleClosePortal}
          />
        ) : (
          <Button
            text={u("confirmButton")}
            iconRight={loading ? "loading" : ""}
            wide
            centerText
            onClick={onClickConfirm(GE.CONFIRM_SUBSCRIBE_FREE)}
            disabled={!email.length || disable}
            loading={loading}
          />
        )}
      </ModalFooter>
    </PortalModal>
  );
};
