import { FFmpeg } from "@ffmpeg/ffmpeg";
import _ from "lodash";

import {
  AiRequestsQuery,
  InputUndress,
  InputUndressType,
} from "@/.gql/graphql";
import { fetchImageData } from "@/app/_actions/getFileUrl";
import { PhotoSettings, Profile, TUndressingFile } from "@/providers";
import { base64ToFile } from "@/utils/base64ToFile";
import { generateUUID } from "@/utils/crypto";

export const updateUndressingsFiles = (
  items: AiRequestsQuery["getAiRequests"]["sets"],
  selectedId: string,
  type: keyof PhotoSettings,
  undressingsFiles: TUndressingFile[],
) => {
  const current = _.find(items, { id: selectedId });
  const itemsWithCollections = _.filter(
    items,
    (item) =>
      item.collectionTag === current?.collectionTag && !!item?.collectionTag,
  );

  if (_.some(itemsWithCollections, (item) => item.id === selectedId)) {
    return itemsWithCollections.map(({ id }) => ({
      id: generateUUID(),
      fileUrl: undressingsFiles[0]?.fileUrl,
      [type]: id,
    }));
  }

  return undressingsFiles;
};

export const prepareInputs = (
  undressingsFiles: TUndressingFile[],
  clickSettings: PhotoSettings,
  aiRequestsIds: string[],
  undressType: InputUndressType,
) =>
  Promise.all(
    _(undressingsFiles)
      .map(async ({ fileUrl, set: defaultSet }) => {
        try {
          let base64Data = fileUrl;

          if (!fileUrl.startsWith("data:image/")) {
            base64Data = await fetchImageData(fileUrl);
          }

          const file = base64ToFile(base64Data);

          const { gender, set, prompt } = clickSettings;

          return {
            file,
            aiRequestsIds: [...aiRequestsIds, defaultSet ?? set],
            gender,
            prompt,
            undressType,
          } as InputUndress;
        } catch (error) {
          console.log(`Failed to fetch file from ${fileUrl}:`, error);
          throw new Error("Failed to fetch file.");
        }
      })
      .compact()
      .value(),
  );

export const checkCoins = (
  profile: NonNullable<Profile>,
  selectedVipSettings: boolean,
  undressingCost: number,
) => {
  if (selectedVipSettings && profile.coins < undressingCost) {
    throw new Error("PATH_NOT_ENOUGH_COINS");
  } else if (
    !selectedVipSettings &&
    profile.coins < undressingCost &&
    profile.freeCoins < undressingCost
  ) {
    throw new Error("PATH_NOT_ENOUGH_COINS");
  }
};

export async function createFileFromUrl(
  url: string,
  fileName: string,
): Promise<File> {
  const response = await fetch(url);
  const blob = await response.blob();
  return new File([blob], fileName, { type: blob.type });
}

export async function cutVideo(
  inputUrl: string,
  startTime: number,
  endTime: number,
) {
  if (endTime <= startTime) {
    throw new Error("End time must be greater than start time");
  }

  const ffmpeg = new FFmpeg();
  await ffmpeg.load();

  const videoFile = await fetch(inputUrl).then((response) =>
    response.arrayBuffer(),
  );
  ffmpeg.writeFile("input.mp4", new Uint8Array(videoFile));

  const duration = (endTime - startTime).toString();
  const startHHMMSS = convertToHHMMSS(startTime);

  await ffmpeg.exec([
    "-ss",
    startHHMMSS,
    "-i",
    "input.mp4",
    "-t",
    duration,
    "-c:v",
    "copy",
    "-c:a",
    "copy",
    "output.mp4",
  ]);

  const data = await ffmpeg.readFile("output.mp4");
  return new Blob([data], { type: "video/mp4" });
}

function convertToHHMMSS(val: number) {
  const hours = String(Math.floor(val / 3600)).padStart(2, "0");
  const minutes = String(Math.floor((val % 3600) / 60)).padStart(2, "0");
  const seconds = String((val % 60).toFixed(2)).padStart(5, "0");
  return `${hours}:${minutes}:${seconds}`;
}
