"use client";

import { PORTALS } from ".constants/portals";
import {
  Box,
  Button,
  ModalContent,
  ModalFooter,
  Text,
  PortalModal,
  usePortalContext,
} from "@cloai/uikit";
import { useTranslations } from "next-intl";
import Image from "next/image";
import { FC } from "react";

import TutorImg from "@/styles/image/tutor/tutor-bdsm.webp";

export const TutorBDSMPortal: FC = () => {
  const t = useTranslations("BDSMModal");
  const { closePortal } = usePortalContext();

  return (
    <PortalModal portalId={PORTALS.TutorBDSM}>
      <ModalContent>
        <Box>
          <Image src={TutorImg} alt="Totur BDSM" placeholder="blur" />

          <Text
            text={t.rich("text", {
              orange: (chunk) => <span data-color="orange">{chunk}</span>,
              count: 9,
            })}
            center
          />
        </Box>
      </ModalContent>

      <ModalFooter>
        <Button
          iconLeft="close"
          text={t("textButton")}
          size="large"
          color="regular"
          wide
          centerText
          onClick={closePortal}
        />
      </ModalFooter>
    </PortalModal>
  );
};
