"use client";

import { GE } from ".constants/gtmEvents";
import { PORTALS } from ".constants/portals";
import { useMutation } from "@apollo/client";
import {
  Box,
  Button,
  Input,
  ModalContent,
  ModalFooter,
  ModalHeader,
  PortalModal,
  RatingStar,
  Textarea,
  usePortalContext,
} from "@cloai/uikit";
import { ChangeEvent, FC, SyntheticEvent, useState } from "react";
import { toast } from "react-toastify";
import { useTranslations } from "use-intl";

import { SEND_REVIEW } from "@/.graphql";
import { usePostContext, useGTMContext } from "@/providers";

export const BlogFeedbackPortal: FC = () => {
  const t = useTranslations("BlogCommentModal");
  const [sendReview, { loading }] = useMutation(SEND_REVIEW);
  const post = usePostContext();
  const { sendGTM } = useGTMContext();
  const { closePortal } = usePortalContext();

  const [nickname, setNickname] = useState("");
  const onChangeNickname = (event: ChangeEvent<HTMLInputElement>) => {
    setNickname(event.target.value);
  };
  const onResetNickname = () => {
    setNickname("");
  };

  const [comment, setComment] = useState("");
  const onChangeComment = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setComment(event.target.value);
  };

  const [activeItem, setActiveItem] = useState<number | null>(null);
  const [isClicked, setIsClicked] = useState(false);
  const handleItemMouseEnter = (event: SyntheticEvent) => {
    if (!isClicked) {
      setActiveItem(Number(event.currentTarget.getAttribute("data-rating")));
    }
  };
  const handleItemMouseLeave = () => {
    if (!isClicked) {
      setActiveItem(null);
    }
  };
  const handleItemClick = async (event: SyntheticEvent) => {
    const rating = Number(event.currentTarget.getAttribute("data-rating"));
    setIsClicked(true);
    setActiveItem(rating);
  };

  const onResetAll = () => {
    setActiveItem(null);
    setNickname("");
    setComment("");
  };

  const onSend = async () => {
    try {
      if (!activeItem) {
        return;
      }
      await sendReview({
        context: {
          clientName: "blog",
        },
        variables: {
          postId: post!.id,
          nickname,
          rating: activeItem,
          review: comment,
        },
      });

      sendGTM({ event: GE.BLOG_POST_MODAL_LEAVE_FEEDBACK });

      closePortal();
      onResetAll();
    } catch (err) {
      const error = err as Error;
      toast.error(error.message);
    }
  };

  return (
    <PortalModal portalId={PORTALS.BlogFeedback} variant="fixed">
      <ModalHeader>{t("title")}</ModalHeader>

      <ModalContent>
        <Input
          icon="person"
          value={nickname}
          onChange={onChangeNickname}
          onReset={onResetNickname}
          minLength={4}
          placeholder={t("nicknamePlaceholder")}
          disabled={loading}
        />

        <Box gap={8} row wide space_between onMouseLeave={handleItemMouseLeave}>
          {[1, 2, 3, 4, 5].map((rating) => (
            <RatingStar
              key={rating}
              active={rating <= (activeItem || 0)}
              rating={rating}
              onMouseEnter={handleItemMouseEnter}
              onClick={handleItemClick}
            />
          ))}
        </Box>

        <Textarea
          value={comment}
          onChange={onChangeComment}
          minLength={4}
          placeholder={t("commentPlaceholder")}
          disabled={loading}
        />
      </ModalContent>

      <ModalFooter>
        <Button
          text={t("buttonText")}
          iconRight={loading ? "refresh" : ""}
          wide
          centerText
          disabled={nickname.length < 4 || comment.length < 4 || !isClicked}
          loading={loading}
          onClick={onSend}
        />
      </ModalFooter>
    </PortalModal>
  );
};
