"use client";

import { GE } from ".constants/gtmEvents";
import { PORTALS } from ".constants/portals";
import {
  Button,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Text,
  PortalModal,
  usePortalContext,
} from "@cloai/uikit";
import { signIn } from "next-auth/webauthn";
import { useTranslations } from "next-intl";
import { FC } from "react";
import { useCookies } from "react-cookie";
import { toast } from "react-toastify";

import OnePass from "@/styles/image/social-buttons/passkey-black.svg";

export const PasskeyPortal: FC = () => {
  const t = useTranslations("PasskeyModal");
  const { closePortal } = usePortalContext();

  const [, setCookie] = useCookies(["passkeyDontShow"]);

  const onClickConfirm = async () => {
    try {
      await signIn("passkey", { action: "register" });
      toast.success(t("toastSuccess"));
      setCookie("passkeyDontShow", 1);
      closePortal();
    } catch (err) {
      const error = err as Error;
      toast.error(error.message);
    }
  };
  const onClickRefuse = () => {
    setCookie("passkeyDontShow", 1);
    closePortal();
  };

  return (
    <PortalModal portalId={PORTALS.Passkey} variant="fixed">
      <ModalHeader>{t("title")}</ModalHeader>

      <ModalContent>
        <Text
          text={t.rich("text", {
            violet: (chunk) => <span data-color="violet">{chunk}</span>,
          })}
          center
        />
      </ModalContent>

      <ModalFooter>
        <Button
          iconLeft={OnePass}
          text={t("confirm")}
          color="passkey"
          centerText
          wide
          onClick={onClickConfirm}
          data-event={GE.AUTH_VIA_PASSKEY__REGISTER}
        />
        <Button
          text={t("refuse")}
          color="outline"
          centerText
          wide
          onClick={onClickRefuse}
        />
      </ModalFooter>
    </PortalModal>
  );
};
