"use client";

import { GE } from ".constants/gtmEvents";
import { PATH_BUY } from ".constants/links";
import { PORTALS } from ".constants/portals";
import undressConfig from ".constants/undress.config.json";
import {
  Button,
  ModalContent,
  ModalFooter,
  ModalHeader,
  PortalModal,
  usePortalContext,
  Text,
} from "@cloai/uikit";
import { useTranslations } from "next-intl";
import { FC } from "react";

import { useButtonIcon } from "@/components/user-flow/controls/useButtonIcon";
import {
  useGTMContext,
  useProfileContext,
  useUndressContext,
} from "@/providers";

import { useAnimate } from "./useAnimate";

export const AnimatePhotoPortal: FC<{ token: string | null }> = ({ token }) => {
  const t = useTranslations("AnimatePhotoModal");
  const profile = useProfileContext();
  const { closePortal } = usePortalContext();
  const { loading } = useUndressContext();
  const { sendGTM } = useGTMContext();

  const userHasManyCoins = (profile?.coins ?? 0) >= undressConfig.undressCost;
  const { animateButtonIcon } = useButtonIcon();

  const handleAnimate = useAnimate(token);

  const handleUpgrade = () => {
    sendGTM({ event: GE.ANIMATE_POPUP__NOT_ENOUGH_COINS_BUY_VIP_COIN });
    closePortal();
  };

  return (
    <PortalModal portalId={PORTALS.AnimatePhoto} variant="fixed">
      <ModalHeader>{t("title")}</ModalHeader>

      <ModalContent>
        <div className="flex flex-col gap-4">
          <video
            loop
            autoPlay
            muted
            playsInline
            poster="/images/animate-result-cover.webp"
            className="aspect-[812/484] w-full rounded-lg border-1 border-white/12 object-cover"
          >
            <source
              src={`/video/animate-result.mp4`}
              type='video/mp4; codecs="hvc1"'
            />
            <source src={`/video/animate-result.webm`} type="video/webm" />
          </video>

          <Text
            text={t("text", {
              count: undressConfig.UndressModifications.duration,
            })}
          />
        </div>
      </ModalContent>

      <ModalFooter>
        {userHasManyCoins ? (
          <Button
            iconLeft="videocam"
            text={t("buttonText")}
            iconRight={animateButtonIcon}
            wide
            centerText
            onClick={handleAnimate}
            loading={loading}
          />
        ) : (
          <Button
            href={PATH_BUY}
            scroll={false}
            text={t("buttonTextUpgrade")}
            color="violet"
            wide
            onClick={handleUpgrade}
          />
        )}
      </ModalFooter>
    </PortalModal>
  );
};
