/**
 * Компонент Claimed отображает информацию о полученном подарке.
 *
 * @remarks
 * Использует данные из `useGiftContext` для получения информации о сегодняшнем подарке (`todayGift`).
 * Определяет цвет иконки или рамки исходя из типа подарка:
 * - Если подарок — `FreeCoins` или `PromoCode`: цвет "violet"
 * - Иначе: цвет "orange"
 *
 * Если подарок является костюмом или позой, отображается соответствующее изображение.
 * Если нет, показывается иконка, соответствующая типу подарка.
 *
 * @returns JSX-элемент, содержащий заголовок, описание подарка и его визуальное представление (картинка или иконка).
 */

import { Title, Text, PlateContent, Box } from "@cloai/uikit";
import { useLocale, useTranslations } from "next-intl";
import Image from "next/image";
import { FC } from "react";

import { UserBonuseType } from "@/.gql/graphql";
import { useGiftContext } from "@/providers";
import { getGiftIcon } from "@/utils/getGiftIcon";

/** Компонент Claimed отображает информацию о полученном подарке. */
export const Claimed: FC = () => {
  const t = useTranslations("GiftClaimedModal");
  const { todayGift } = useGiftContext();
  const locale = useLocale();

  /** Определяем цвет в зависимости от типа подарка */
  const color =
    todayGift?.type === UserBonuseType.FreeCoins ||
    todayGift?.type === UserBonuseType.PromoCode
      ? "violet"
      : "orange";

  /**
   * Если todayGift является костюмом или позой,
   * используем соответствующее изображение.
   */
  const image =
    todayGift?.__typename === "CostumeUserBonus"
      ? todayGift.costumeImage
      : todayGift?.__typename === "PoseUserBonus"
        ? todayGift.poseImage
        : null;

  return (
    <Box gap={32}>
      {/* Заголовок */}
      <Title Component={"span"} text={t("title")} center />

      <PlateContent gap={8} theme="lighten" wide>
        {/* Описание подарка на текущей локали или на английском, если локаль не найдена */}
        <Text
          text={
            todayGift?.description?.[locale] ||
            todayGift?.description?.["en"] ||
            ""
          }
          fontWeight={500}
          center
        />

        <Box center>
          {image ? (
            // Если есть изображение, отображаем его
            <Image
              src={image}
              width={80}
              height={80}
              alt="Gift Image"
              style={{
                objectFit: "cover",
                width: 80,
                height: 80,
                borderRadius: 8,
                border: "2px solid var(--Color-Orange-Main, #E86F00)",
              }}
            />
          ) : (
            // Иначе отображаем иконку, зависящую от типа подарка
            <Text
              text=""
              color={color}
              lineHeight={100}
              center
              className="material-icons"
              data-icon={getGiftIcon[todayGift?.type ?? "Coins"]}
              style={{ fontSize: "80px" }}
            />
          )}
        </Box>
      </PlateContent>
    </Box>
  );
};
