"use client";

import { GE } from ".constants/gtmEvents";
import { useTranslations } from "next-intl";
import { ChangeEvent, SyntheticEvent, useEffect, useState } from "react";
import { toast } from "react-toastify";

import { getFileUrl } from "@/app/_actions/getFileUrl";
import { useGTMContext } from "@/providers";
import {
  TUndressingFile,
  useUndressContext,
} from "@/providers/UndressProvider";
import { generateUUID } from "@/utils/crypto";

export const useUploadPhotoByUrlForm = (onClose: () => void) => {
  const t = useTranslations("HomePage.toast");
  const { sendGTM } = useGTMContext();
  const {
    setUndressingsFiles,
    setClickFileId,
    undressingsFiles,
    maxFilesPhoto,
  } = useUndressContext();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (undressingsFiles.length === 0) {
      return;
    }
    setClickFileId(undressingsFiles[undressingsFiles.length - 1].id);
  }, [setClickFileId, undressingsFiles]);

  const [photoLink, setPhotoLink] = useState("");

  const onSetPhotoLink = (event: ChangeEvent<HTMLInputElement>) => {
    setPhotoLink(event.target.value);
  };

  const onResetPhotoLink = () => {
    setPhotoLink("");
  };

  const onClickUpload = async (event: SyntheticEvent<HTMLElement>) => {
    event.preventDefault();

    if (loading) {
      return;
    }

    setLoading(true);

    try {
      const { file, files, error } = await getFileUrl(photoLink);

      if (error) {
        throw new Error(error);
      }

      const currentFileCount = undressingsFiles.length;
      const maxFileCount = maxFilesPhoto;
      const availableSlots = maxFileCount - currentFileCount;

      if (availableSlots <= 0) {
        toast.error(t("maxLimit"));
        setLoading(false);
        return;
      }

      let imagesToAdd: TUndressingFile[] = [];

      if (files && files.length > 0) {
        imagesToAdd = files.slice(0, availableSlots).map((fileUrl: string) => ({
          id: generateUUID(),
          fileUrl,
        }));
      } else if (file) {
        imagesToAdd = [{ id: generateUUID(), fileUrl: file }];
      }

      if (imagesToAdd.length > 0) {
        setUndressingsFiles((_images) => [..._images, ...imagesToAdd]);
      }

      sendGTM({
        event: photoLink.includes("instagram")
          ? GE.UPLOAD_PHOTO_FROM_INSTAGRAM
          : GE.UPLOAD_PHOTO,
        ecommerce: {
          insta_type: photoLink.includes("instagram")
            ? photoLink.includes("/p/")
              ? "single_image"
              : "profile"
            : "",
        },
      });
      setPhotoLink("");
      onClose();
    } catch {
      toast.error(t("imageNotFound"));
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    photoLink,
    onSetPhotoLink,
    onResetPhotoLink,
    onClickUpload,
  };
};
