"use client";

import { PORTALS } from ".constants/portals";
import { ApolloError, useMutation, useLazyQuery } from "@apollo/client";
import {
  Button,
  Input,
  ModalContent,
  ModalFooter,
  ModalHeader,
  PortalModal,
  usePortalContext,
  Text,
} from "@cloai/uikit";
import { useTranslations } from "next-intl";
import {
  ChangeEvent,
  FC,
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useCookies } from "react-cookie";
import { toast } from "react-toastify";

import { GetPromocodeQuery } from "@/.gql/graphql";
import { GET_PROMOCODE, SIGN_PROMOCODE } from "@/.graphql";

// NOTE: Определяем enum для состояния проверки промокода
enum PromocodeStatus {
  NotChecked = 0, // NOTE: Промокод не проверен
  Error1 = 1, // NOTE: Промокод не найден
  Error2 = 2, // NOTE: Другие ошибки (если есть)
  Success = 3, // NOTE: Промокод успешно найден
}

// NOTE: Компонент ActivatePromocodePortal отвечает за активацию промокода пользователя
export const ActivatePromocodePortal: FC = () => {
  const t = useTranslations("ActivatePromocodeModal"); // NOTE: Функция для переводов в модале
  const { closePortal } = usePortalContext(); // NOTE: Функция для закрытия портала
  const [cookies, setCookies] = useCookies(["use_gift_promo"]); // NOTE: Используем cookies для сохранения промокода
  const [promocodeValue, setPromocodeValue] = useState(""); // NOTE: Значение поля ввода промокода
  const [promocodeStatus, setPromocodeStatus] = useState<PromocodeStatus>(
    PromocodeStatus.NotChecked,
  ); // NOTE: Состояние проверки промокода

  // NOTE: Используем useLazyQuery для проверки промокода
  const [fetchPromocode, { loading: loadingQuery, data }] =
    useLazyQuery<GetPromocodeQuery>(GET_PROMOCODE);

  // NOTE: Используем useMutation для активации промокода
  const [signPromocode, { loading: loadingMutation }] =
    useMutation(SIGN_PROMOCODE);

  // NOTE: Функция для очистки состояний
  const onClear = useCallback(() => {
    setPromocodeStatus(PromocodeStatus.NotChecked);
    setPromocodeValue("");
    // NOTE: Нет необходимости явно очищать promocode, так как data.getPromocode будет обновляться
  }, []);

  // NOTE: Функция для проверки промокода
  const handleCheckPromocode = useCallback(
    async (value: string) => {
      try {
        const { data } = await fetchPromocode({
          variables: { promocode: value },
        });
        const getPromocode = data?.getPromocode ?? null;
        // NOTE: Обновляем состояние match на основе результата
        if (getPromocode) {
          setPromocodeStatus(PromocodeStatus.Success); // NOTE: Промокод найден успешно
        } else {
          setPromocodeStatus(PromocodeStatus.Error1); // NOTE: Промокод не найден
        }
      } catch (err) {
        const error = err as ApolloError;
        // NOTE: Устанавливаем match на основе типа ошибки из GraphQL
        setPromocodeStatus(
          (error?.graphQLErrors?.[0]?.extensions?.type ??
            PromocodeStatus.NotChecked) as PromocodeStatus,
        );
      }
    },
    [fetchPromocode],
  );

  // NOTE: Обработчик изменения значения промокода
  const onChangePromocodeValue = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value.trim();
      setPromocodeValue(value);

      if (value.length < 4) {
        setPromocodeStatus(PromocodeStatus.NotChecked); // NOTE: Очистка состояния при недостаточной длине
        return;
      }

      // NOTE: Проверяем промокод при достижении минимальной длины
      handleCheckPromocode(value);
    },
    [handleCheckPromocode],
  );

  // NOTE: useEffect для инициализации промокода из cookies при монтировании
  useEffect(() => {
    const savedPromocode = cookies.use_gift_promo;
    if (savedPromocode) {
      setPromocodeValue(savedPromocode);
      handleCheckPromocode(savedPromocode);
    }
  }, [cookies.use_gift_promo, handleCheckPromocode]);

  // NOTE: useMemo для генерации текста модала на основе состояния promocodeStatus и данных промокода
  const text = useMemo(() => {
    switch (promocodeStatus) {
      case PromocodeStatus.Error1:
        return t.rich("error1", {
          span: (chunks) => <span data-color="orange">{chunks}</span>,
        });
      case PromocodeStatus.Error2:
        return t.rich("error2", {
          span: (chunks) => <span data-color="red">{chunks}</span>,
        });
      case PromocodeStatus.Success:
        return t.rich("receive", {
          count:
            data?.getPromocode?.__typename === "UserPromoCodeFreeCoins"
              ? data.getPromocode.freeCoins
              : data?.getPromocode?.__typename === "UserPromoCodeCoins" ||
                  data?.getPromocode?.__typename === "Promocode"
                ? data.getPromocode.coins
                : 0,
          quality:
            data?.getPromocode?.type === 1
              ? t("quality.vip")
              : t("quality.free"),
          span: (chunks) => <span data-color="violet">{chunks}</span>,
        });
      default:
        return t("text"); // NOTE: Стандартный текст, если промокод не проверен или введён недостаточно
    }
  }, [promocodeStatus, data, t]);

  // NOTE: Функция для закрытия модала и очистки состояния
  const handleClose = useCallback(() => {
    closePortal();
    onClear();
  }, [closePortal, onClear]);

  // NOTE: Обработчик клика по кнопке активации промокода
  const onClick = useCallback(
    async (event: SyntheticEvent<HTMLElement>) => {
      event.preventDefault();

      if (loadingMutation || loadingQuery) {
        return; // NOTE: Если уже выполняется запрос, не делаем ничего
      }

      try {
        // NOTE: Выполняем мутацию для активации промокода
        await signPromocode({
          variables: {
            promocode: promocodeValue,
          },
        });

        // NOTE: Если промокод совпадает с сохранённым в cookies, очищаем его
        if (promocodeValue === cookies.use_gift_promo) {
          setCookies("use_gift_promo", "");
        }

        handleClose(); // NOTE: Закрываем модал после успешной активации
      } catch (err) {
        const error = err as ApolloError;
        // NOTE: Обработка ошибок мутации
        switch (error?.graphQLErrors?.[0]?.extensions?.type) {
          case 3:
            toast.error(t("promoLimitExceeded")); // NOTE: Ограничение промокода превышено
            break;
          default:
            toast.error(error.message); // NOTE: Другое сообщение об ошибке
        }
      }
    },
    [
      loadingMutation,
      loadingQuery,
      signPromocode,
      promocodeValue,
      cookies.use_gift_promo,
      handleClose,
      setCookies,
      t,
    ],
  );

  return (
    <PortalModal
      portalId={PORTALS.ActivatePromocode}
      variant="fixed"
      onClose={handleClose}
    >
      <ModalHeader>{t("title")}</ModalHeader>

      <ModalContent>
        <Input
          placeholder={t("placeholder")}
          onChange={onChangePromocodeValue}
          minLength={4}
          value={promocodeValue}
          icon="content_paste"
          onReset={onClear}
          error={
            promocodeStatus === PromocodeStatus.Error1 ||
            promocodeStatus === PromocodeStatus.Error2
          }
        />

        <Text text={text} center />
      </ModalContent>

      <ModalFooter>
        <Button
          text={t("buttonText")}
          loading={loadingQuery || loadingMutation}
          disabled={promocodeStatus !== PromocodeStatus.Success}
          size="large"
          wide
          centerText
          onClick={onClick}
        />

        <Text
          text={t.rich("notice", {
            span: (chunks) => <span data-color="red">{chunks}</span>,
          })}
          center
          color="gray"
          fontSize={12}
        />
      </ModalFooter>
    </PortalModal>
  );
};
