"use client";

import {
  Box,
  Button,
  ModalContent,
  ModalHeader,
  PortalModal,
} from "@cloai/uikit";
import { useTranslations } from "next-intl";
import { FC, useCallback } from "react";

import { LanguageCode, useFlag } from "@/components/hooks/useLanguage";
import { langList } from "@/i18n";
import { PORTALS } from "~/.constants/portals";

import { useChangeLanguage } from "./useChangeLanguage";

export const ChangeLangPortal: FC = () => {
  const t = useTranslations("LanguageModal");

  const flag = useFlag();

  const { selectedLanguage, isPending, handleLanguageChange } =
    useChangeLanguage();

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      const lang = event.currentTarget.getAttribute(
        "data-lang",
      ) as LanguageCode;
      if (lang) {
        handleLanguageChange(lang);
      }
    },
    [handleLanguageChange],
  );

  return (
    <PortalModal portalId={PORTALS.ChangeLang}>
      <ModalHeader>{t("title")}</ModalHeader>

      <ModalContent>
        <Box gap={4} wide>
          {langList.map((lang) => {
            const isSelected = selectedLanguage === lang.key;
            const langCode = lang.key as LanguageCode;

            return (
              <Button
                key={lang.key}
                iconLeft={flag(langCode)}
                iconRight={isSelected ? (isPending ? "loading" : "done") : ""}
                text={lang.value}
                color={isSelected ? "regular" : "none"}
                wide
                centerText
                data-lang={lang.key}
                onClick={handleClick}
                disabled={isPending}
                loading={isPending && isSelected}
              />
            );
          })}
        </Box>
      </ModalContent>
    </PortalModal>
  );
};

ChangeLangPortal.displayName = "ChangeLangPortal";
