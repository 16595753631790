"use client";

import { GTMEvent } from ".constants/gtmEvents";
import { MIN_CHECK_REGEX, VALID_EMAIL_REGEX } from ".constants/texts";
import { ApolloError } from "@apollo/client";
import { useTranslations } from "next-intl";
import { ChangeEvent, useCallback, useState } from "react";
import { toast } from "react-toastify";

import { addEmailsToAMallingList } from "@/app/_actions/sendpulse";
import { ToastIcon } from "@/components/ToastIcon";
import { ToastMessage } from "@/components/ToastMessage";
import { useGTMContext } from "@/providers";
import { generateUUID } from "@/utils/crypto";

export const useEmail = () => {
  const tToast = useTranslations("HomePage.toast");
  const { sendGTM } = useGTMContext();

  const [isConfirm, setIsConfirm] = useState(false);
  const [loading, setLoading] = useState(false);

  const [email, setEmail] = useState("");
  const [disable, setDisable] = useState(true);
  const [error, setError] = useState(false);

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const dataEvent = event.currentTarget.value;
      setEmail(dataEvent);
      if (email === "") {
        setDisable(true);
        setError(false);
      }
      setDisable(!VALID_EMAIL_REGEX.test(dataEvent));
      setError(
        !VALID_EMAIL_REGEX.test(dataEvent) && MIN_CHECK_REGEX.test(dataEvent),
      );
    },
    [email],
  );

  const handleReset = useCallback(() => {
    setEmail("");
    setDisable(true);
    setError(false);
  }, []);

  const onClickConfirm = useCallback(
    (event: GTMEvent) => async () => {
      sendGTM({ event: event });

      try {
        setLoading(true);
        await addEmailsToAMallingList(email);
        setIsConfirm(true);
      } catch (error) {
        const err = error as ApolloError;
        toast.error(
          <ToastMessage title={tToast("error")} text={err.message} />,
          {
            icon: ({ type }) => ToastIcon(type),
            toastId: generateUUID(),
          },
        );
      } finally {
        setLoading(false);
      }
    },
    [email, sendGTM, tToast],
  );

  return {
    disable,
    email,
    error,
    handleChange,
    handleReset,
    isConfirm,
    loading,
    onClickConfirm,
    setIsConfirm,
  };
};
