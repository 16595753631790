import { UserBonuseType } from "@/.gql/graphql";

export const getGiftIcon = {
  [UserBonuseType.FreeCoins]: "star", // freecoins
  [UserBonuseType.Coins]: "diamond", // vip coins
  [UserBonuseType.PromoCode]: "local_activity", // promocode
  [UserBonuseType.Pose]: "self_improvement", // pose
  [UserBonuseType.Costume]: "checkroom", // costume
  // [UserBonuseType.Bonus]: "sell", // bonus to CoinPack
};
