import { useMemo } from "react";

import Tutor1LNudiva from "@/styles/image/tutor-nudiva/tutor-1-left.webp";
import Tutor1RNudiva from "@/styles/image/tutor-nudiva/tutor-1-right.webp";
import Tutor2LNudiva from "@/styles/image/tutor-nudiva/tutor-2-left.webp";
import Tutor2RNudiva from "@/styles/image/tutor-nudiva/tutor-2-right.webp";
import Tutor3LNudiva from "@/styles/image/tutor-nudiva/tutor-3-left.webp";
import Tutor3RNudiva from "@/styles/image/tutor-nudiva/tutor-3-right.webp";
import Tutor1L from "@/styles/image/tutor/tutor-1.webp";
import Tutor1R from "@/styles/image/tutor/tutor-2.webp";
import Tutor2L from "@/styles/image/tutor/tutor-3.webp";
import Tutor2R from "@/styles/image/tutor/tutor-4.webp";
import Tutor3L from "@/styles/image/tutor/tutor-5.webp";
import Tutor3R from "@/styles/image/tutor/tutor-6.webp";

export const useGirls = (nudiva: boolean) => {
  const grils = useMemo(
    () =>
      nudiva
        ? {
            Tutor1L: Tutor1LNudiva,
            Tutor1R: Tutor1RNudiva,
            Tutor2L: Tutor2LNudiva,
            Tutor2R: Tutor2RNudiva,
            Tutor3L: Tutor3LNudiva,
            Tutor3R: Tutor3RNudiva,
          }
        : { Tutor1L, Tutor1R, Tutor2L, Tutor2R, Tutor3L, Tutor3R },
    [nudiva],
  );

  return grils;
};
