"use client";

import { PORTALS } from ".constants/portals";
import {
  Button,
  Input,
  ListBlock,
  ListBlockItemWIcon,
  ModalContent,
  ModalFooter,
  ModalHeader,
  PortalModal,
  usePortalContext,
} from "@cloai/uikit";
import { useTranslations } from "next-intl";
import { FC } from "react";

import { useUploadPhotoByUrlForm } from "./hooks";

export const AttachPhotoByInstaPortal: FC = () => {
  const t = useTranslations("AttachPhotoByInstagramModal");
  const { closePortal } = usePortalContext();

  const {
    loading,
    photoLink,
    onSetPhotoLink,
    onResetPhotoLink,
    onClickUpload,
  } = useUploadPhotoByUrlForm(closePortal);

  const handleClose = () => {
    closePortal();
    onResetPhotoLink();
  };

  return (
    <PortalModal portalId={PORTALS.AttachPhotoByInsta} onClose={handleClose}>
      <ModalHeader>{t("title")}</ModalHeader>

      <ModalContent>
        <ListBlock theme="clear" dotsList gap={8}>
          <ListBlockItemWIcon icon="fiber_manual_record" text={t("text1")} />
          <ListBlockItemWIcon icon="fiber_manual_record" text={t("text2")} />
          <ListBlockItemWIcon icon="fiber_manual_record" text={t("text3")} />
          <ListBlockItemWIcon icon="fiber_manual_record" text={t("text4")} />
          <ListBlockItemWIcon icon="fiber_manual_record" text={t("text5")} />
        </ListBlock>

        <Input
          placeholder="https://www..."
          value={photoLink}
          onChange={onSetPhotoLink}
          onReset={onResetPhotoLink}
          minLength={5}
          icon="content_paste"
          disabled={loading}
        />
      </ModalContent>

      <ModalFooter>
        <Button
          text={t("buttonText")}
          iconRight={loading ? "progress_activity" : ""}
          loading={loading}
          disabled={!photoLink}
          size="large"
          wide
          centerText
          onClick={onClickUpload}
        />
      </ModalFooter>
    </PortalModal>
  );
};
