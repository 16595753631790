/**
 * Отвечает за отображение модального окна с системой подарков (GiftRoadmapPortal).
 *
 * @remarks
 * Функциональность:
 * - Отображение текущей или завершённой "дорожной карты" подарков (Roadmap).
 * - Возможность получения доступного подарка (Claimed).
 * - Если подарок — промокод, после успешного получения появляется кнопка для его активации.
 *
 * Основные понятия:
 * - `hasToClaimGift`: указывает, доступен ли сейчас подарок к получению.
 * - `isFinish`: указывает, что все подарки уже получены.
 * - `giftsList`: список доступных подарков.
 * - `todayGift`: данные о сегодняшнем подарке.
 * - `timeToNextGift`: время до следующего подарка (используется таймер).
 *
 * Логика:
 * - При открытии портала (если `currentPortal === PORTALS.GiftRoadmap`), в cookie записывается `giftPopupIsShowed = 1`, чтобы избежать повторного показа в течение суток.
 * - Если подарок доступен (`hasToClaimGift = true`), можно его "забрать".
 * - При нажатии на кнопку "забрать" отправляется GraphQL-запрос `CLAIM_BONUS`.
 * - Если подарок — промокод, после успешного получения предлагается окно активации.
 * - Если все подарки получены (`isFinish = true`), отображается сообщение об окончании.
 * - Если подарок ещё не доступен, отображается таймер обратного отсчёта.
 *
 * @returns JSX-элемент модального окна с подарками.
 */

"use client";

import { GE } from ".constants/gtmEvents";
import { PORTALS } from ".constants/portals";
import { ApolloError, useMutation } from "@apollo/client";
import {
  Box,
  Button,
  ModalContent,
  ModalFooter,
  PortalModal,
  usePortalContext,
  ButtonWrapper,
  ButtonTag,
  TRANSITION_VARIANTS,
} from "@cloai/uikit";
import { AnimatePresence, motion } from "framer-motion";
import _ from "lodash";
import { useTranslations } from "next-intl";
import Image from "next/image";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useCookies } from "react-cookie";
import { toast } from "react-toastify";
import useMeasure from "react-use-measure";

import { CLAIM_BONUS } from "@/.graphql";
import { useGiftContext, useGTMContext } from "@/providers";
import GiftIcon from "@/styles/image/gift-icon-lg.webp";
import GiftOpenIcon from "@/styles/image/gift-icon-open-lg.webp";

import { Claimed } from "./Claimed";
import { Roadmap } from "./Roadmap";
import { Timer } from "./Timer";

/** Конфигурация анимации для анимирования крышки подарка */
const GIFT_ANIMATE = {
  initial: { translateX: 0, translateY: 0, rotate: 0 },
  enter: { translateX: -6, translateY: -14, rotate: -22 },
  exit: { translateX: 0, translateY: 0, rotate: 0 },
};

/** Отвечает за отображение модального окна с системой подарков */
export const GiftRoadmapPortal: FC = () => {
  const t = useTranslations("GiftRoadmapModal");
  const g = useTranslations("GiftClaimedModal");
  const { currentPortal, openPortal, closePortal } = usePortalContext();
  const { sendGTM } = useGTMContext();
  const { hasToClaimGift, isFinish, giftsList, todayGift, timeToNextGift } =
    useGiftContext();
  const [, setCookies] = useCookies(["use_gift_promo", "giftPopupIsShowed"]);

  const [signClaimBonus, { loading }] = useMutation(CLAIM_BONUS);
  const [isShowDetails, setShowDetails] = useState(false);
  const [ref, bounds] = useMeasure();

  /**
   * Обработчик получения подарка.
   * Отправляет GraphQL-запрос, включает отображение подробностей о подарке, проигрывает звук.
   */
  const onClickClaim = useCallback(async () => {
    sendGTM({ event: GE.CLICK_CLAIM_GIFT });

    try {
      await signClaimBonus({ refetchQueries: ["GetBonuses"] });
      setShowDetails(true);

      const audio = new Audio("/audio/open-gift.mp3");
      audio.play();
    } catch (err) {
      const error = err as ApolloError;
      toast.error(
        g(error?.graphQLErrors?.[0]?.extensions?.error as never) ??
          error.message,
      );
    }
  }, [g, sendGTM, signClaimBonus]);

  /**
   * Закрывает портал и сбрасывает состояние подробностей.
   */
  const handleClosePortal = useCallback(() => {
    setShowDetails(false);
    closePortal();
  }, [closePortal]);

  /** Определяем, является ли текущий подарок промокодом */
  const isPromo = todayGift?.__typename === "PromoCodeUserBonus";

  /**
   * Обрабатывает использование промокода после получения подарка:
   * - Записывает промокод в куки
   * - Закрывает текущее модальное окно
   * - Открывает окно активации промокода
   */
  const handleUsePromo = useCallback(() => {
    setCookies(
      "use_gift_promo",
      isPromo ? todayGift?.promoCode?.promoCode : "",
    );
    handleClosePortal();
    openPortal(PORTALS.ActivatePromocode);
  }, [handleClosePortal, isPromo, openPortal, setCookies, todayGift]);

  /**
   * Определяет, какую кнопку отображать в нижней части модального окна,
   * в зависимости от текущего состояния:
   * - Если подарок уже получен и показаны подробности: кнопка для закрытия или перехода к промокоду.
   * - Если подарок доступен: кнопка "забрать" + таймер до следующего.
   * - Если все получено: кнопка "завершить".
   * - Иначе: таймер ожидания доступности подарка.
   */
  const renderButton = useMemo(() => {
    switch (true) {
      case isShowDetails:
        return (
          <Button
            text={g(isPromo ? "buttonTextPromo" : "buttonText")}
            size="large"
            centerText
            wide
            onClick={isPromo ? handleUsePromo : handleClosePortal}
          />
        );
      case hasToClaimGift:
        return (
          <ButtonWrapper>
            <Button
              text={t("buttonText")}
              iconRight={loading ? "loading" : ""}
              size="large"
              centerText
              wide
              onClick={onClickClaim}
              loading={loading}
            />
            <ButtonTag
              text={
                <Timer localeKey={"buttomTimer"} endDate={timeToNextGift} />
              }
              color="violet"
            />
          </ButtonWrapper>
        );
      case isFinish:
        return (
          <Button
            text={t("buttonTextFinish")}
            size="large"
            centerText
            wide
            onClick={handleClosePortal}
          />
        );
      default:
        return (
          <Button
            iconLeft="hourglass_top"
            text={
              <Timer
                localeKey="buttonTextWait"
                endDate={timeToNextGift ?? ""}
                placement="button"
              />
            }
            size="large"
            color="passive"
            centerText
            wide
          />
        );
    }
  }, [
    g,
    handleClosePortal,
    handleUsePromo,
    hasToClaimGift,
    isFinish,
    isPromo,
    isShowDetails,
    loading,
    onClickClaim,
    t,
    timeToNextGift,
  ]);

  useEffect(() => {
    if (currentPortal === PORTALS.GiftRoadmap) {
      setCookies("giftPopupIsShowed", 1, {
        maxAge: 60 * 60 * 24 * 1, // примерно день в секундах
      });
    }
  }, [currentPortal, setCookies]);

  /** Если подарков нет, модалка не отображается */
  if (_.size(giftsList) === 0) {
    return null;
  }

  return (
    <PortalModal portalId={PORTALS.GiftRoadmap} onClose={handleClosePortal}>
      <ModalContent className="!-mx-popup-padding-x gap-8 pr-[calc(var(--spacing-popup-padding-x)-var(--spacing-scrollbar))] pl-popup-padding-x">
        <Box center style={{ position: "relative" }}>
          {/** Анимация крышки подарка при его открытии */}
          <motion.div
            key="gift-lid"
            initial="initial"
            animate={isShowDetails ? "enter" : "initial"}
            exit="exit"
            variants={GIFT_ANIMATE}
            style={{ position: "absolute" }}
          >
            <Image
              src={GiftOpenIcon}
              width={96}
              height={96}
              alt="Gift"
              fetchPriority="high"
              priority
            />
          </motion.div>
          <Image
            src={GiftIcon}
            width={96}
            height={96}
            alt="Gift"
            fetchPriority="high"
            priority
          />
        </Box>

        {/** Обёртка с динамической высотой, зависящей от содержимого */}
        <motion.div
          animate={{ height: bounds.height }}
          transition={{ duration: 0.2, ease: "easeInOut" }}
        >
          {/** Обёртка для измерения высоты контента */}
          <div ref={ref}>
            <AnimatePresence mode="wait" initial={false}>
              <motion.div
                key={`gift-claimed-${isShowDetails}`}
                initial="exit"
                animate="enter"
                exit="exit"
                variants={TRANSITION_VARIANTS.modalInOut}
              >
                {/** В зависимости от состояния показываем Claimed или Roadmap */}
                {isShowDetails ? <Claimed /> : <Roadmap />}
              </motion.div>
            </AnimatePresence>
          </div>
        </motion.div>
      </ModalContent>

      <ModalFooter>{renderButton}</ModalFooter>
    </PortalModal>
  );
};
