"use client";

import undressConfig from ".constants/undress.config.json";
import { useMemo } from "react";

import { useProfileContext, useUndressContext } from "@/providers";

export const useButtonIcon = () => {
  const profile = useProfileContext();
  const { undressingCost, selectedVipSettings } = useUndressContext();

  /**
   * Проверяем, хватает ли VIP-коинов на раздевание
   */
  const haveVipCoinsForUndress = useMemo(
    () => profile?.coins! > undressingCost,
    [profile?.coins, undressingCost],
  );

  /**
   * Иконка для кнопки раздевания
   */
  const undressButtonIcon = (
    <div className="flex items-center justify-center">
      {undressingCost}
      <span
        className="material-icons"
        data-icon={
          /** Если НЕ выбраны VIP-настройки или если хватает VIP-коинов на генку */
          selectedVipSettings || haveVipCoinsForUndress ? "diamond" : "star"
        }
      />
    </div>
  );

  /**
   * Иконка для кнопки анимации
   */
  const animateButtonIcon = (
    <div className="flex items-center justify-center">
      {undressConfig.undressCostAnimate}
      <span className="material-icons" data-icon="diamond" />
    </div>
  );

  /**
   * Размер скидки для функции `ReUse Face` в процентах
   */
  const reUseFaceDiscoint =
    ((undressConfig.undressCost - undressConfig.undressCostRemix) /
      undressConfig.undressCost) *
    100;

  /**
   * Проверяем, хватает ли VIP-коинов для `ReUse Face`
   */
  const haveVipCoinsForAnimate = useMemo(
    () => profile?.coins! > undressConfig.undressCostRemix,
    [profile?.coins],
  );

  const reUseFaceDiscountTag = (
    <div className="flex items-center justify-center gap-1">
      -{reUseFaceDiscoint}%
      <span
        className="material-icons !text-base/4"
        data-icon={haveVipCoinsForAnimate ? "diamond" : "star"}
      />
    </div>
  );

  return { undressButtonIcon, animateButtonIcon, reUseFaceDiscountTag };
};
