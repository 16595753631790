/**
 * Компонент Item отвечает за отображение информации о подарке, доступном (или доступном в будущем) пользователю.
 *
 * @remarks
 * Функциональность:
 * - Определяет тип подарка (free или vip) на основании типа бонуса.
 * - Отображает текстовое описание подарка, зависящее от его типа (промокод, поза, костюм, количество монет).
 * - Если подарок ещё не доступен, но появится на следующий день, показывает таймер до его появления.
 * - Использует компонент GiftItem для отображения иконки, заголовка и статуса подарка.
 *
 * @param props - Свойства компонента.
 * @param props.gift - Данные о текущем подарке (тип, день, статус и т.д.).
 *
 * @returns JSX-элемент, отображающий иконку, текст и состояние подарка, или null, если подарок отсутствует.
 */

import { GiftItem } from "@cloai/uikit";
import { TIconKey } from "@cloai/uikit/build/v2/types";
import { useTranslations } from "next-intl";
import { FC, memo } from "react";

import { UserBonuseType } from "@/.gql/graphql";
import { TGiftContextProps, useGiftContext } from "@/providers";
import { getGiftIcon } from "@/utils/getGiftIcon";

import { Timer } from "./Timer";

/** Компонент для отображения отдельного подарка в дорожной карте */
export const Item: FC<{ gift: TGiftContextProps["todayGift"] }> = memo(
  ({ gift }) => {
    const t = useTranslations("HomePage");
    const { hasToClaimGift, day, timeToNextGift } = useGiftContext();

    /**
     * Если объект gift отсутствует, возвращаем null, что предотвращает рендеринг компонента.
     * Это позволяет убедиться, что компонент не будет пытаться отобразить данные,
     * которых нет или которые могут вызвать ошибку.
     */
    if (!gift) {
      return null;
    }

    /**
     * Определяем тип подарка:
     * - Если это FreeCoins или PromoCode, считаем подарок "бесплатным" (free).
     * - В остальных случаях — "vip".
     */
    const type =
      gift.type === UserBonuseType.FreeCoins ||
      gift.type === UserBonuseType.PromoCode
        ? "free"
        : "vip";

    /**
     * Определяет текст для отображения внутри подарка в зависимости от его типа.
     * - PromoCode: "Promo"
     * - Pose: "Pose"
     * - Costume: "Nude"
     * - CoinsUserBonus: показывает количество монет "x{coins}"
     * - FreeCoinsUserBonus: показывает количество бесплатных монет "x{freeCoins}"
     */
    const text = () => {
      switch (true) {
        case gift.type === UserBonuseType.PromoCode:
          return "Promo";
        case gift.type === UserBonuseType.Pose:
          return "Pose";
        case gift.type === UserBonuseType.Costume:
          return "Nude";
        case gift.__typename === "CoinsUserBonus":
          return `x${gift.coins}`;
        case gift.__typename === "FreeCoinsUserBonus":
          return `x${gift.freeCoins}`;
        default:
          return "";
      }
    };

    /**
     * Если подарок будет доступен завтра (gift.day - day === 1) и при этом сейчас нет подарка,
     * показываем таймер до следующего подарка.
     */
    const timer =
      gift.day! - day === 1 && !hasToClaimGift ? (
        <Timer localeKey="timer" endDate={timeToNextGift ?? ""} />
      ) : undefined;

    return (
      <GiftItem
        title={t("dayNumber", { number: gift.day })} // NOTE: Заголовок в формате "Day {number}"
        timer={timer} // NOTE: Таймер до следующего подарка
        icon={getGiftIcon[gift.type] as TIconKey} // NOTE: Иконка подарка в зависимости от его типа
        text={text()} // NOTE: Текст подарка
        type={type} // NOTE: Тип подарка для стилизации: free или vip
        isActive={gift.day === day} // NOTE: isActive: если день подарка совпадает с текущим днём
        isDone={gift.status === 1} // NOTE: isDone: если подарок уже был получен (status === 1)
      />
    );
  },
);
Item.displayName = "Item";
