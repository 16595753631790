"use client";

import Image, { StaticImageData } from "next/image";
import { FC, memo, ReactNode } from "react";

import { cn } from "@/utils/cn";

// >>> <ModalCompareImg />
type TModalCompareImgProps = {
  img: string | StaticImageData;
  meta: string;
  text?: string | ReactNode;
  color?: "white" | "green" | "red";
};
export const ModalCompareImg: FC<TModalCompareImgProps> = memo(
  ({ img, meta, text, color = "white" }) => {
    const textColor = {
      white: "text-white",
      green: "text-green-500",
      red: "text-red-500",
    };

    return (
      <div className="relative w-full overflow-hidden rounded-lg border-1 border-regular-foreground/10">
        <Image
          src={img}
          alt={meta}
          className="block h-auto w-full"
          placeholder="blur"
        />
        <div
          className={cn(
            "absolute right-1 bottom-1 left-1 h-10 rounded-lg bg-regular/70 backdrop-blur-md",
            "gap-2 text-center text-lg font-semibold whitespace-pre-line",
            "flex items-center justify-center",
            textColor[color],
          )}
        >
          {text}
        </div>
      </div>
    );
  },
);
ModalCompareImg.displayName = "ModalCompareImg";
// <<< <ModalCompareImg />
