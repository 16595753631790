"use client";

import { PATH_BUY } from ".constants/links";
import { PORTALS } from ".constants/portals";
import {
  Box,
  Button,
  ContentText,
  // ModalCompareImg,
  ModalContent,
  ModalFooter,
  Text,
  PortalModal,
  usePortalContext,
} from "@cloai/uikit";
import { useTranslations } from "next-intl";
import { FC, useEffect } from "react";
import { useCookies } from "react-cookie";

import { useProfileContext } from "@/providers";
import ImgOriginal from "@/styles/image/faq-hd-1.jpg";
import ImgHD from "@/styles/image/faq-hd-2.jpg";

import { ModalCompareImg } from "../tutor/ModalCompareImg";

export const TutorHDPortal: FC = () => {
  const t = useTranslations("HDModal");
  const { currentPortal, closePortal } = usePortalContext();
  const profile = useProfileContext();
  const [, setCookies] = useCookies(["tutorHdIsReaded"]);

  useEffect(() => {
    if (currentPortal === PORTALS.TutorHD) {
      setCookies("tutorHdIsReaded", 1, {
        maxAge: 60 * 60 * 24 * 365, // примерно год в секундах
      });
    }
  }, [currentPortal, setCookies]);

  const noCoins = !(profile?.coins! > 0);

  return (
    <PortalModal portalId={PORTALS.TutorHD}>
      <ModalContent>
        <Box gap={4} row>
          <ModalCompareImg
            img={ImgOriginal}
            meta={t("imgMeta.alt")}
            text={
              <>
                <span>Original</span>
                <ContentText text="480 px" />
              </>
            }
          />

          <ModalCompareImg
            img={ImgHD}
            meta={t("imgMeta.alt")}
            text={
              <>
                <Box gap={4} row center>
                  HD <div className="material-icons" data-icon="diamond"></div>
                </Box>
                <ContentText text="1024 px" />
              </>
            }
          />
        </Box>

        <Text
          text={t.rich("text", {
            span: (chunks) => <span data-color="orange">{chunks}</span>,
          })}
          center
        />
      </ModalContent>

      <ModalFooter>
        <Box gap={12} center row>
          <Button
            iconLeft="close"
            text={t("close")}
            color="regular"
            size="large"
            wide
            centerText={!noCoins}
            onClick={closePortal}
          />

          {noCoins && (
            <Button
              href={PATH_BUY}
              scroll={false}
              iconLeft="verified"
              text={t("buy")}
              size="large"
              wide
              centerText={!noCoins}
              onClick={closePortal}
              data-event="popap_hd_buy_coin"
            />
          )}
        </Box>
      </ModalFooter>
    </PortalModal>
  );
};
