"use client";

import { GE } from ".constants/gtmEvents";
import { PATH_BUY } from ".constants/links";
import { PORTALS } from ".constants/portals";
import {
  Box,
  Button,
  ButtonTag,
  ButtonWrapper,
  ModalContent,
  ModalFooter,
  PortalModal,
  Subtitle,
  Text,
  usePortalContext,
} from "@cloai/uikit";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { useTranslations } from "next-intl";
import { FC, useEffect, useState } from "react";
import { useCookies } from "react-cookie";

import { usePaymentContext, useGTMContext } from "@/providers";
import { getLocalePrice } from "@/utils/getLocalPrice";

import { Timer } from "../gift-roadmap/Timer";

dayjs.extend(utc);

export const PayHoldPortal: FC = () => {
  const t = useTranslations("PayHoldModal");
  const { currentPortal, closePortal } = usePortalContext();
  const { userPromoPacks } = usePaymentContext();
  const { sendGTM } = useGTMContext();
  const [, setCookies] = useCookies(["payholdIsShowed"]);

  const pack = userPromoPacks[0];

  const [endTime, setEndTime] = useState("");

  useEffect(() => {
    if (currentPortal === PORTALS.PayHold) {
      setCookies("payholdIsShowed", 1, {
        maxAge: 60 * 60 * 24 * 1, // примерно 1 день в секундах
      });

      sendGTM({ event: GE.PAYHOLD_MODAL__SHOW });
      setEndTime(dayjs().add(15, "minute").format());

      const playAudio = () => {
        const audio = new Audio("/audio/sms.mp3");
        audio.play();
      };
      playAudio();
    }
  }, [currentPortal, sendGTM, setCookies]);

  const onClick = () => {
    sendGTM({ event: GE.PAYHOLD_MODAL__CLICK_PACKEGE });
    closePortal();
  };

  return (
    <PortalModal portalId={PORTALS.PayHold} fixed>
      <ModalContent>
        <Text text="🤩" fontSize={64} center />
        <Subtitle text={t("title")} center />
        <Text
          text={t.rich("text", {
            count: pack?.discount,
            orange: (chunk) => <span data-color="orange">{chunk}</span>,
          })}
          fontWeight={700}
          center
        />
      </ModalContent>

      <ModalFooter>
        <ButtonWrapper>
          <Button
            href={`${PATH_BUY}/${pack?.coin}`}
            scroll={false}
            text={t("buttonTextPack", {
              coin: pack?.coin,
              price: getLocalePrice(pack?.currency, pack?.price),
            })}
            size="large"
            wide
            onClick={onClick}
          />
          <ButtonTag
            text={
              <Box gap={4} row center>
                <Text
                  text=""
                  className="material-icons"
                  data-icon="schedule"
                  fontSize={14}
                />
                <Timer localeKey="timer" endDate={endTime} />
              </Box>
            }
            color="red"
          />
        </ButtonWrapper>
      </ModalFooter>
    </PortalModal>
  );
};
