"use client";

import { PORTALS } from ".constants/portals";
import {
  Button,
  ModalContent,
  ModalFooter,
  Box,
  Text,
  PortalModal,
  usePortalContext,
  Title,
} from "@cloai/uikit";
import { useTranslations } from "next-intl";
import Image from "next/image";
import { FC } from "react";

import IconCamera from "@/styles/image/camera.webp";

export const UploadVideoPortal: FC = () => {
  const t = useTranslations("UploadVideoModal");
  const { closePortal } = usePortalContext();

  return (
    <PortalModal portalId={PORTALS.UploadVideo} fixed>
      <ModalContent>
        <Box center style={{ padding: "20px" }}>
          <Image
            src={IconCamera}
            alt="camera icon"
            width={64}
            height={65}
            placeholder="blur"
          />
        </Box>

        <Box gap={16}>
          <Title Component={"p"} color="orange" text={t("title")} center />
          <ul>
            <Text Component={"li"} text={t("clause1")} />
            <Text
              Component={"li"}
              text={t("clause2", {
                formats: ".mp4, .mpeg, .mov, .ogg",
              })}
            />
            <Text Component={"li"} text={t("clause3")} />
          </ul>
        </Box>
      </ModalContent>

      <ModalFooter>
        <Button text={t("buttonText")} onClick={closePortal} wide />
      </ModalFooter>
    </PortalModal>
  );
};
