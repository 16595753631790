/**
 * Компонент Timer отображает оставшееся время в формате ЧЧ:ММ:СС.
 *
 * @remarks
 * Использует хук `useTimer` для вычисления оставшегося времени до указанной даты.
 * Переводы текста осуществляются при помощи `useTranslations` из "GiftRoadmapModal".
 *
 * @param localeKey - Ключ локализации, определяющий текст, в который будут подставлены часы, минуты и секунды.
 *   Возможные значения: "timer", "buttonTextWait", "buttomTimer".
 * @param endDate - Дата в формате строки, до которой отсчитывается время.
 * @param placement - Определяет, где будет использоваться таймер.
 *   - "button": при достижении нулевого времени вернёт перевод для "buttonTextRequest"
 *   - "timer": будет показывать оставшееся время в любом случае
 *
 * @returns Компонент, отображающий оставшееся время или специальный текст при `hours`, `minutes`, `seconds` равных "00", когда placement="button".
 */

import { useTimer } from "@cloai/uikit";
import { useTranslations } from "next-intl";
import { FC, memo } from "react";

/** Компонент Timer отображает оставшееся время в формате ЧЧ:ММ:СС */
export const Timer: FC<{
  localeKey: "timer" | "buttonTextWait" | "buttomTimer";
  endDate: string;
  placement?: "button" | "timer";
}> = memo(({ localeKey, endDate, placement = "timer" }) => {
  const t = useTranslations("GiftRoadmapModal");

  const time = useTimer(endDate);
  const hours = time?.formattedHours ?? "00";
  const minutes = time?.formattedMinutes ?? "00";
  const seconds = time?.formattedSeconds ?? "00";

  // NOTE: Если время вышло, возвращаем текст "заглушку"
  if (
    hours === "00" &&
    minutes === "00" &&
    seconds === "00" &&
    placement === "button"
  ) {
    return t("buttonTextRequest");
  }

  // NOTE: В остальных случаях возвращаем локализованную строку с подставленными часами, минутами и секундами
  return t(localeKey, {
    hours: hours || "00",
    minutes: minutes || "00",
    seconds: seconds || "00",
  });
});

Timer.displayName = "Timer";
