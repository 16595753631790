"use client";

import { PORTALS } from ".constants/portals";
import {
  Box,
  Button,
  ModalContent,
  ModalFooter,
  ModalHeader,
  PortalModal,
  usePortalContext,
} from "@cloai/uikit";
import { useTranslations } from "next-intl";
import { FC, useEffect } from "react";
import { useCookies } from "react-cookie";

export const TutorPosePortal: FC = () => {
  const t = useTranslations("PoseModal");
  const { currentPortal, closePortal } = usePortalContext();
  const [, setCookies] = useCookies(["tutorPoseIsReaded"]);

  useEffect(() => {
    if (currentPortal === PORTALS.TutorPose) {
      setCookies("tutorPoseIsReaded", 1, {
        maxAge: 60 * 60 * 24 * 365, // примерно год в секундах
      });
    }
  }, [currentPortal, setCookies]);

  return (
    <PortalModal portalId={PORTALS.TutorPose} variant="fixed">
      <ModalHeader>{t("title")}</ModalHeader>

      <ModalContent>
        <Box>
          <video
            loop
            autoPlay
            muted
            playsInline
            style={{
              borderRadius: "8px",
              border: "1px solid rgba(255, 255, 255, 0.12)",
              width: "100%",
              aspectRatio: "398 / 270",
            }}
            poster="/images/tutor-video-pose-cover.webp"
          >
            <source
              src="/video/tutor-video-pose.mp4"
              type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'
            />
          </video>
        </Box>
      </ModalContent>

      <ModalFooter>
        <Button
          iconLeft="checkroom"
          text={t("textButton")}
          wide
          centerText
          onClick={closePortal}
          data-event="popap_pose_try_pose"
        />
      </ModalFooter>
    </PortalModal>
  );
};
