"use client";

import { GE } from ".constants/gtmEvents";
import { PORTALS } from ".constants/portals";
import {
  Box,
  Button,
  ModalContent,
  ModalFooter,
  Text,
  PaginationDot,
  PortalModal,
  usePortalContext,
} from "@cloai/uikit";
import { useTranslations } from "next-intl";
import { FC, useEffect, useMemo, useState } from "react";
import { useCookies } from "react-cookie";

import { useCompany } from "@/components/hooks/useCompany";
import { useGTMContext } from "@/providers";

import { ModalCompareImg } from "./ModalCompareImg";
import { useGirls } from "./useGirls";

export const TutorPortal: FC = () => {
  const t = useTranslations("TutorModal");
  const { currentPortal, closePortal } = usePortalContext();
  const { sendGTM } = useGTMContext();
  const [, setCookies] = useCookies([
    "tutorReaded",
    "ga_ab",
    "ga_abc",
    "segment_ab",
  ]);
  const { isNudiva } = useCompany();
  const { Tutor1L, Tutor1R, Tutor2L, Tutor2R, Tutor3L, Tutor3R } =
    useGirls(isNudiva);

  const texts = useMemo(
    () => [
      t("cloth.step1"),
      t("cloth.step2"),
      t("cloth.step3"),
      t("cloth.step4"),
    ],
    [t],
  );
  const [active, setActive] = useState(0);

  const handleClose = () => {
    closePortal();
    setActive(0);
  };

  const onBack = () => {
    setActive((active) => active - 1);
  };

  useEffect(() => {
    if (currentPortal === PORTALS.Tutor) {
      setCookies("tutorReaded", 1, {
        maxAge: 60 * 60 * 24 * 365, // примерно год в секундах
      });
    }
  }, [currentPortal, setCookies]);

  useEffect(() => {
    if (active === 4) {
      sendGTM({ event: GE.TUTOR_READED });

      handleClose();
    }
    // eslint-disable-next-line react-compiler/react-compiler
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active]);

  const onNext = () => {
    setActive((active) => active + 1);
  };

  const getImage1 = useMemo(() => {
    switch (active) {
      case 0:
        return Tutor1L;
      case 1:
        return Tutor2L;
    }

    return Tutor3L;
  }, [Tutor1L, Tutor2L, Tutor3L, active]);

  const getImage2 = useMemo(() => {
    switch (active) {
      case 0:
        return Tutor1R;
      case 1:
        return Tutor2R;
    }

    return Tutor3R;
  }, [Tutor1R, Tutor2R, Tutor3R, active]);

  const getText = useMemo(
    () => texts[active] || texts[texts.length - 1],
    [active, texts],
  );

  const showPhoto = useMemo(() => (active === 3 ? false : true), [active]);

  return (
    <PortalModal portalId={PORTALS.Tutor} onClose={handleClose}>
      <ModalContent>
        <Box gap={4} row>
          {showPhoto ? (
            <>
              <ModalCompareImg
                img={getImage1}
                meta={t("imgMeta.alt")}
                text={t("good")}
                color="green"
              />
              <ModalCompareImg
                img={getImage2}
                meta={t("imgMeta.alt")}
                text={t("bad")}
                color="red"
              />
            </>
          ) : (
            <video
              loop
              autoPlay
              muted
              playsInline
              style={{
                borderRadius: "8px",
                border: "1px solid rgba(255, 255, 255, 0.12)",
                width: "100%",
                aspectRatio: "398 / 270",
              }}
              poster="/images/tutor-video-pose-cover.webp"
            >
              <source
                src="/video/tutor-video-pose.mp4"
                type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'
              />
            </video>
          )}
        </Box>

        <span style={{ minHeight: "110px" }}>
          <Text text={getText} />
        </span>

        <Box gap={8} row center>
          {texts.map((_item, index) => (
            <PaginationDot key={index} active={active === index} />
          ))}
        </Box>
      </ModalContent>

      <ModalFooter>
        <Box gap={12} center row>
          <Button
            iconLeft="arrow_back"
            text={t("back")}
            size="large"
            wide
            color="violet"
            onClick={onBack}
            disabled={!active}
          />

          <Button
            iconRight={active >= 3 ? "close" : "arrow_forward"}
            text={active >= 3 ? t("close") : t("next")}
            size="large"
            wide
            onClick={onNext}
          />
        </Box>
      </ModalFooter>
    </PortalModal>
  );
};
