"use client";

import { PORTALS } from ".constants/portals";
import { EC_COUNTRIES_LIST } from ".constants/texts";
import {
  Box,
  Button,
  ModalContent,
  ModalFooter,
  ModalHeader,
  PortalModal,
  Text,
  usePortalContext,
} from "@cloai/uikit";
import { useTranslations } from "next-intl";
import { CSSProperties, FC } from "react";
import { useCookies } from "react-cookie";

import { AsulabelLogo } from "@/components/AsulabelLogo";
import { HeaderLogo } from "@/components/header/HeaderLogo";
import { RtaLogo } from "@/components/RtaLogo";
import { useHeadersContext } from "@/providers";

const PORTAL_STYLES = {
  "--spacing-popup-widht": "calc(183 * var(--spacing))",
  "--color-popup": "var(--color-clothoff-landing-primary)",
  "--spacing-popup-padding-x": "calc(var(--spacing) * 6)",
  "--spacing-popup-padding-y": "calc(var(--spacing) * 6)",
  "--color-scrollbar-track": "oklch(from var(--color-zinc-700) l c h / 50%)",
  "--color-scrollbar-thumb": "var(--color-zinc-700)",
} as CSSProperties;

export const RulesPortal: FC = () => {
  const t = useTranslations("RulesBlock");
  const { closePortal } = usePortalContext();

  const [, setCookies] = useCookies(["rulesAccepted"]);

  const headers = useHeadersContext();
  const country =
    headers["ddg-connecting-country"] ??
    headers["x-mock-country"] ??
    headers["cf-ipcountry"] ??
    "";

  const EUcountry = EC_COUNTRIES_LIST.includes(country);

  const onClick = () => {
    setCookies("rulesAccepted", 1);
    closePortal();
  };

  return (
    <PortalModal
      portalId={PORTALS.Rules}
      dismissible={false}
      style={PORTAL_STYLES}
    >
      <ModalHeader>
        <HeaderLogo className="mb-2 lg:text-base" />
        {t(EUcountry ? "eu_h1" : "h1")}
      </ModalHeader>

      <ModalContent className="justify-between gap-4">
        {!EUcountry ? (
          <>
            <ul>
              <Box gap={8}>
                <Text Component="li" text={t("eu_step1")} fontSize={16} />
                <Text Component="li" text={t("eu_step2")} fontSize={16} />
                <Text Component="li" text={t("eu_step3")} fontSize={16} />
                <Text Component="li" text={t("eu_step4")} fontSize={16} />
              </Box>
            </ul>

            <ul className="opacity-35">
              <Text Component="li" text={t("notice1")} fontSize={12} />
              <Text Component="li" text={t("notice2")} fontSize={12} />
              <Text Component="li" text={t("notice3")} fontSize={12} />
            </ul>
          </>
        ) : (
          <ul>
            <Box gap={8}>
              <Text Component="li" text={t("step1")} fontSize={16} />
              <Text Component="li" text={t("step2")} fontSize={16} />
              <Text Component="li" text={t("step3")} fontSize={16} />
              <Text Component="li" text={t("step4")} fontSize={16} />
            </Box>
          </ul>
        )}

        <div className="mx-auto flex items-center gap-4 py-2">
          <AsulabelLogo />
          <RtaLogo />
        </div>
      </ModalContent>

      <ModalFooter>
        <Button
          text={t("accept")}
          iconLeft="check"
          centerText
          onClick={onClick}
          wide
          className="mx-auto max-w-84"
        />
      </ModalFooter>
    </PortalModal>
  );
};
