/**
 * Компонент Roadmap отвечает за отображение дорожной карты подарков с динамическим отображением
 * трех подарков: текущего, предыдущего и следующего (если они есть), а также последнего подарка в списке.
 *
 * @remarks
 * Функциональность:
 * - При помощи контекста `useGiftContext` получает данные о:
 *   - `hasToClaimGift`: есть ли сейчас доступный к получению подарок
 *   - `day`: текущий день
 *   - `giftsList`: полный список подарков
 *   - `isFinish`: все ли подарки уже получены
 *
 * Поведение:
 * - `lastGift`: последний подарок из списка, отображается справа.
 * - `listFiltered`: берёт 3 подарка вокруг текущего дня (вчера, сегодня, завтра) или,
 *   если сегодня — первый день, берёт сегодняшний, завтрашний и послезавтрашний день.
 * - Если в `listFiltered` меньше 3 элементов, берём последние 3 подарка из списка.
 * - Использует анимации framer-motion для плавного появления/исчезновения элементов.
 * - Стрелка между центральным блоком и последним подарком меняет цвет при достижении конца списка.
 * - Текст под списком отражает текущее состояние: готовность к получению, необходимость подождать или завершённость.
 *
 * @returns JSX-элемент, отображающий дорожную карту подарков.
 */

import { Title, Box, Text } from "@cloai/uikit";
import { AnimatePresence, motion } from "framer-motion";
import _ from "lodash";
import { useTranslations } from "next-intl";
import { FC, useMemo } from "react";

import { useGiftContext } from "@/providers";

import { Item } from "./Item";

/** Анимации для плавного появления/скрытия элементов */
const animations = {
  initial: { scale: 0, opacity: 0 },
  animate: { scale: 1, opacity: 1 },
  exit: { scale: 0, opacity: 0 },
  transition: {
    type: "spring",
    stiffness: 900,
    damping: 40,
  },
};

/** Компонент Roadmap отвечает за отображение дорожной карты подарков с динамическим отображением */
export const Roadmap: FC = () => {
  const t = useTranslations("GiftRoadmapModal");
  const { hasToClaimGift, day, giftsList, isFinish } = useGiftContext();

  /** Получаем последний подарок (конечная точка дорожной карты) */
  const lastGift = _.last(giftsList)!;

  /** Исключаем последний подарок для работы с центральными элементами */
  const giftsListWithoutLast = _.dropRight(giftsList);

  /** Находим индекс текущего дня среди оставшихся подарков */
  const todayIndex = _.findIndex(
    giftsListWithoutLast,
    (gift) => gift.day === day,
  );

  /**
   * Формируем список из трёх элементов вокруг текущего дня:
   * - Если сегодня не первый день, берём (вчера, сегодня, завтра)
   * - Если сегодня первый день, берём (сегодня, завтра, послезавтра)
   */
  const listFiltered = useMemo(() => {
    if (todayIndex > 0) {
      return _.slice(giftsListWithoutLast, todayIndex - 1, todayIndex + 2);
    }
    return _.slice(giftsListWithoutLast, todayIndex, todayIndex + 3);
  }, [todayIndex, giftsListWithoutLast]);

  /**
   * Если после фильтрации меньше 3 элементов (например, в самом конце списка),
   * берём последние 3 подарка из массива без последнего.
   */
  const listFilteredWithFallback = useMemo(
    () =>
      listFiltered.length < 3
        ? _.slice(
            giftsListWithoutLast,
            Math.max(giftsListWithoutLast.length - 3, 0),
          )
        : listFiltered,
    [listFiltered, giftsListWithoutLast],
  );

  /**
   * Определяем, нужно ли перекрасить стрелку.
   * Если до последнего подарка остаётся ровно один день, меняем цвет стрелки.
   */
  const isColoredArrow =
    (lastGift?.day ?? 0) - (_.last(listFiltered)?.day ?? 0) === 1;

  /**
   * Определяем, какой текст отображать под списком подарков:
   * - Если все подарки уже получены: "textFinish"
   * - Если есть доступный подарок: "textReady"
   * - Иначе: "textWait"
   */
  const text = isFinish
    ? "textFinish"
    : hasToClaimGift
      ? "textReady"
      : "textWait";

  /** Заголовок дорожной карты */
  const title = useMemo(() => t("title"), [t]);

  /** Текстовое описание состояния, с использованием t.rich для стилизованного текста */
  const displayText = useMemo(
    () =>
      t.rich(text, {
        orange: (chunk) => <span data-color="orange">{chunk}</span>,
      }),
    [text, t],
  );

  /** Если подарков нет совсем, не отображаем компонент */
  if (_.size(giftsList) === 0) {
    return null;
  }

  return (
    <Box gap={32}>
      {/** Заголовок дорожной карты */}
      <Title Component={"span"} text={title} center />

      {/** Основной ряд с подарками */}
      <Box gap={8} row center>
        {/**
         * Анимированное появление списка подарков.
         * Используем AnimatePresence и motion.div для плавного обновления при смене элементов.
         */}
        <AnimatePresence mode="popLayout" initial={false}>
          <motion.div>
            <Box gap={8} row center>
              {_.map(listFilteredWithFallback, (gift) => (
                <motion.div key={gift.day} layout {...animations}>
                  <Item key={gift.day} gift={gift} />
                </motion.div>
              ))}
            </Box>
          </motion.div>
        </AnimatePresence>

        {/** Стрелка, указывающая на последний подарок */}
        <Text
          text=""
          className="material-icons"
          data-icon="chevron_right"
          fontSize={24}
          color={isColoredArrow ? "violet" : "notice"}
          style={{ margin: "-4px" }}
        />

        {/** Отображаем последний подарок */}
        <Item gift={lastGift} />
      </Box>

      {/** Подпись, зависящая от состояния (готов, подождать, завершено) */}
      <Text text={displayText} center fontSize={16} fontWeight={700} />
    </Box>
  );
};
